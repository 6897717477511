import React from 'react';
import {
  Button,
  HStack,
  Icon,
  IconButton,
  LinearGradient,
  ScreenContainer,
  Touchable,
  VStack,
  withTheme,
} from '@draftbit/ui';
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';
import { Image, Modal, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const HomeScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [textInput2Value, setTextInput2Value] = React.useState('');
  const [textInput3Value, setTextInput3Value] = React.useState('');
  const [textInput4Value, setTextInput4Value] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { alignContent: 'center' },
        dimensions.width
      )}
    >
      <LinearGradient
        endX={100}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Primary gradient'].props}
        color1={theme.colors.text.strong}
        color2={theme.colors.branding.primary}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.LinearGradientStyles(theme)['Primary gradient'].style,
            {
              alignItems: 'stretch',
              paddingBottom: [
                { minWidth: Breakpoints.Laptop, value: 50 },
                { minWidth: Breakpoints.Tablet, value: 45 },
              ],
            }
          ),
          dimensions.width
        )}
      >
        {/* View 3 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: {
                minWidth: Breakpoints.Laptop,
                value: 'flex-start',
              },
              alignSelf: { minWidth: Breakpoints.Laptop, value: 'auto' },
              backgroundColor: theme.colors.background.brand,
            },
            dimensions.width
          )}
        >
          <HStack
            {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.HStackStyles(theme)['H Stack'].style,
                {
                  justifyContent: 'space-between',
                  marginBottom: [
                    { minWidth: Breakpoints.Laptop, value: 20 },
                    { minWidth: Breakpoints.Mobile, value: 10 },
                  ],
                  marginLeft: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 45 },
                    { minWidth: Breakpoints.Desktop, value: 40 },
                    { minWidth: Breakpoints.BigScreen, value: 75 },
                  ],
                  marginRight: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 45 },
                    { minWidth: Breakpoints.Desktop, value: 45 },
                    { minWidth: Breakpoints.BigScreen, value: 75 },
                  ],
                  marginTop: 15,
                }
              ),
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('HomeScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <VStack
                {...GlobalStyles.VStackStyles(theme)['V Stack'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.VStackStyles(theme)['V Stack'].style,
                  dimensions.width
                )}
              >
                <HStack
                  {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.HStackStyles(theme)['H Stack'].style,
                      {
                        alignItems: [
                          { minWidth: Breakpoints.Laptop, value: 'flex-end' },
                          { minWidth: Breakpoints.Mobile, value: 'flex-end' },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <Image
                    resizeMode={'cover'}
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    source={imageSource(Images['mainsightsfaviconlogo10243'])}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'].style,
                        { height: 30, marginRight: 5, width: 30 }
                      ),
                      dimensions.width
                    )}
                  />
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Section heading'].style,
                        {
                          color: palettes.App.Orange,
                          fontFamily: 'Poppins_900Black',
                          lineHeight: 14,
                          paddingBottom: 0,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'M&A INSIGHTS'}
                  </Text>
                </HStack>

                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Section heading'].style,
                      {
                        fontFamily: 'Quicksand_400Regular',
                        fontSize: 12,
                        marginTop: 5,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'(NKP - we just got a new logo)'}
                </Text>
              </VStack>
            </Touchable>
            <>
              {dimensions.width >= Breakpoints.Laptop ? null : (
                <View>
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'hamburger_nav',
                          value: true,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    icon={'Ionicons/menu-sharp'}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: null,
                          color: theme.colors.text.strong,
                          fontSize: {
                            minWidth: Breakpoints.Desktop,
                            value: 20,
                          },
                        }
                      ),
                      dimensions.width
                    )}
                    title={'\n'}
                  />
                </View>
              )}
            </>
            {/* View 2 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <View>
                  {/* H Stack 2 */}
                  <>
                    {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                      <HStack
                        {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.HStackStyles(theme)['H Stack'].style,
                          dimensions.width
                        )}
                      >
                        {/* Button 3 */}
                        <Button
                          iconPosition={'left'}
                          onPress={() => {
                            try {
                              setGlobalVariableValue({
                                key: 'login_options_laptop',
                                value: true,
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'].style,
                              {
                                backgroundColor: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors.branding.primary,
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Quicksand_400Regular',
                                },
                                marginRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 10,
                                },
                              }
                            ),
                            dimensions.width
                          )}
                          title={'LOG IN'}
                        />
                        <Button
                          iconPosition={'left'}
                          onPress={() => {
                            try {
                              navigation.navigate('AboutScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'].style,
                              {
                                backgroundColor: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'rgba(0, 0, 0, 0)',
                                },
                                borderColor: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors.branding.primary,
                                },
                                borderRadius: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 5,
                                },
                                borderWidth: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 1,
                                },
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors.branding.primary,
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Quicksand_500Medium',
                                },
                                marginLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 10,
                                },
                                marginRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 10,
                                },
                              }
                            ),
                            dimensions.width
                          )}
                          title={'ABOUT'}
                        />
                        {/* Button 2 */}
                        <Button
                          iconPosition={'left'}
                          onPress={() => {
                            try {
                              navigation.navigate('ContactScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'].style,
                              {
                                backgroundColor: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors.background.brand,
                                },
                                borderColor: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors.branding.primary,
                                },
                                borderRadius: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 5,
                                },
                                borderWidth: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 1,
                                },
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors.branding.primary,
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Quicksand_500Medium',
                                },
                                marginLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 10,
                                },
                                marginRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 10,
                                },
                              }
                            ),
                            dimensions.width
                          )}
                          title={'CONTACT'}
                        />
                        {/* Button 4 */}
                        <Button
                          iconPosition={'left'}
                          onPress={() => {
                            try {
                              navigation.navigate('ScheduleDemoScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'].style,
                              {
                                backgroundColor: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors.background.brand,
                                },
                                borderColor: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors.branding.primary,
                                },
                                borderRadius: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 5,
                                },
                                borderWidth: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 1,
                                },
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors.branding.primary,
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Quicksand_500Medium',
                                },
                                marginLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 10,
                                },
                                marginRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 10,
                                },
                              }
                            ),
                            dimensions.width
                          )}
                          title={'DEMO'}
                        />
                        {/* Button 5 */}
                        <Button
                          iconPosition={'left'}
                          onPress={() => {
                            try {
                              navigation.navigate('CompanyOwnersScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'].style,
                              {
                                backgroundColor: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'rgba(0, 0, 0, 0)',
                                },
                                borderColor: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors.branding.primary,
                                },
                                borderRadius: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 5,
                                },
                                borderWidth: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 1,
                                },
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: theme.colors.branding.primary,
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'Quicksand_500Medium',
                                },
                                marginLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 10,
                                },
                                marginRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 10,
                                },
                              }
                            ),
                            dimensions.width
                          )}
                          title={'FOR COMPANY OWNERS'}
                        />
                      </HStack>
                    )}
                  </>
                </View>
              )}
            </>
          </HStack>
        </View>
        {/* View 4 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: [
                { minWidth: Breakpoints.Laptop, value: 'center' },
                { minWidth: Breakpoints.Mobile, value: 'center' },
              ],
              justifyContent: [
                { minWidth: Breakpoints.Laptop, value: 'center' },
                { minWidth: Breakpoints.Mobile, value: 'center' },
              ],
              marginTop: { minWidth: Breakpoints.BigScreen, value: 50 },
            },
            dimensions.width
          )}
        >
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: 'flex-start',
                alignItems: 'center',
                alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
                maxWidth: [
                  { minWidth: Breakpoints.Laptop, value: '75%' },
                  { minWidth: Breakpoints.BigScreen, value: '60%' },
                  { minWidth: Breakpoints.Desktop, value: '60%' },
                ],
              },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Hero heading'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Hero heading'].style,
                  {
                    color: theme.colors.background.brand,
                    fontFamily: 'Quicksand_700Bold',
                    fontSize: [
                      { minWidth: Breakpoints.Mobile, value: 20 },
                      { minWidth: Breakpoints.BigScreen, value: 36 },
                      { minWidth: Breakpoints.Laptop, value: 24 },
                      { minWidth: Breakpoints.Desktop, value: 30 },
                    ],
                    marginBottom: 15,
                    marginLeft: [
                      { minWidth: Breakpoints.Laptop, value: 45 },
                      { minWidth: Breakpoints.Mobile, value: 20 },
                    ],
                    marginRight: [
                      { minWidth: Breakpoints.Mobile, value: 20 },
                      { minWidth: Breakpoints.Laptop, value: 45 },
                    ],
                    marginTop: 45,
                    textAlign: 'center',
                  }
                ),
                dimensions.width
              )}
            >
              {
                'The one exhaustive intelligence hub to expand and inform your pipeline'
              }
            </Text>
            {/* Text 2 */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Hero sub heading'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Hero sub heading'].style,
                  {
                    color: theme.colors.background.brand,
                    fontFamily: 'Quicksand_600SemiBold',
                    fontSize: [
                      { minWidth: Breakpoints.Mobile, value: 16 },
                      { minWidth: Breakpoints.Laptop, value: 18 },
                      { minWidth: Breakpoints.Desktop, value: 20 },
                    ],
                    marginBottom: 25,
                    marginLeft: [
                      { minWidth: Breakpoints.Mobile, value: 20 },
                      { minWidth: Breakpoints.Laptop, value: 45 },
                    ],
                    marginRight: [
                      { minWidth: Breakpoints.Mobile, value: 20 },
                      { minWidth: Breakpoints.Laptop, value: 45 },
                    ],
                    marginTop: 15,
                    textAlign: 'center',
                  }
                ),
                dimensions.width
              )}
            >
              {
                'Tap into our proprietary investment research to uncover, qualify, and prioritize opportunities in the Nordics and DACH not covered elsewhere.'
              }
            </Text>
            {/* Image 3 */}
            <>
              {dimensions.width >= Breakpoints.Laptop ? null : (
                <Image
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  resizeMode={'contain'}
                  source={imageSource(Images['iphone15rehau'])}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      {
                        height: 350,
                        marginBottom: 10,
                        marginLeft: 10,
                        marginRight: 10,
                        marginTop: 10,
                        opacity: 0.8,
                        width: 200,
                      }
                    ),
                    dimensions.width
                  )}
                />
              )}
            </>
            {/* Image 3 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <Image
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  resizeMode={'contain'}
                  source={imageSource(Images['screenshotdesktopifmfront'])}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      {
                        height: [
                          { minWidth: Breakpoints.Laptop, value: 500 },
                          { minWidth: Breakpoints.Desktop, value: 650 },
                        ],
                        marginTop: [
                          { minWidth: Breakpoints.Desktop, value: -50 },
                          { minWidth: Breakpoints.Laptop, value: -40 },
                        ],
                        width: [
                          { minWidth: Breakpoints.Laptop, value: 700 },
                          { minWidth: Breakpoints.Desktop, value: 850 },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                />
              )}
            </>
          </View>

          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
                maxWidth: [
                  { minWidth: Breakpoints.Laptop, value: '75%' },
                  { minWidth: Breakpoints.BigScreen, value: '60%' },
                  { minWidth: Breakpoints.Desktop, value: '60%' },
                ],
                zIndex: 1000,
              },
              dimensions.width
            )}
          >
            <Button
              iconPosition={'left'}
              onPress={() => {
                try {
                  navigation.navigate('ScheduleDemoScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.ButtonStyles(theme)['Demo button 1'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Demo button 1'].style,
                  {
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderColor: theme.colors.border.brand,
                    borderWidth: 1,
                    fontFamily: 'Quicksand_400Regular',
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 14 },
                      { minWidth: Breakpoints.Desktop, value: 18 },
                    ],
                    marginBottom: 45,
                    marginTop: 20,
                    position: 'relative',
                    zIndex: 1000,
                  }
                ),
                dimensions.width
              )}
              title={'REQUEST DEMO'}
            />
            {/* Text 3 */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['section body'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['section body'].style,
                dimensions.width
              )}
            >
              {
                'M&A Insights from NKP is the preferred intelligence platform for more than 150 private equity funds and M&A advisory firms operating in the Nordic and DACH regions'
              }
            </Text>
          </View>
        </View>
        <>
          {dimensions.width >= Breakpoints.Laptop ? null : (
            <Modal
              animationType={'none'}
              supportedOrientations={['portrait', 'landscape']}
              presentationStyle={'pageSheet'}
              transparent={true}
              visible={Constants['hamburger_nav']}
            >
              <View
                style={StyleSheet.applyWidth(
                  { backgroundColor: theme.colors.background.brand },
                  dimensions.width
                )}
              >
                <HStack
                  {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.HStackStyles(theme)['H Stack'].style,
                      {
                        justifyContent: 'space-between',
                        marginBottom: 10,
                        marginLeft: 20,
                        marginRight: 20,
                        marginTop: 15,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('HomeScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <HStack
                      {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.HStackStyles(theme)['H Stack'].style,
                          {
                            alignItems: [
                              {
                                minWidth: Breakpoints.Laptop,
                                value: 'flex-end',
                              },
                              {
                                minWidth: Breakpoints.Mobile,
                                value: 'flex-end',
                              },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      <Image
                        resizeMode={'cover'}
                        {...GlobalStyles.ImageStyles(theme)['Image'].props}
                        source={imageSource(
                          Images['mainsightsfaviconlogo10243']
                        )}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ImageStyles(theme)['Image'].style,
                            { height: 30, marginRight: 5, width: 30 }
                          ),
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Section heading']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Section heading']
                              .style,
                            {
                              color: palettes.App.Orange,
                              fontFamily: 'Poppins_900Black',
                              lineHeight: 14,
                              paddingBottom: 0,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'M&A INSIGHTS'}
                      </Text>
                    </HStack>
                  </Touchable>
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'hamburger_nav',
                          value: false,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    icon={'Ionicons/menu-sharp'}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          color: theme.colors.text.strong,
                        }
                      ),
                      dimensions.width
                    )}
                    title={''}
                  />
                </HStack>

                <VStack
                  {...GlobalStyles.VStackStyles(theme)['V Stack'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.VStackStyles(theme)['V Stack'].style,
                      {
                        alignContent: {
                          minWidth: Breakpoints.Tablet,
                          value: 'center',
                        },
                        alignItems: {
                          minWidth: Breakpoints.Tablet,
                          value: 'center',
                        },
                        marginBottom: 25,
                        marginLeft: 20,
                        marginRight: 20,
                        marginTop: 25,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* Button 2 */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        navigation.navigate('HomeScreen');
                        setGlobalVariableValue({
                          key: 'hamburger_nav',
                          value: false,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          color: theme.colors.text.strong,
                          fontFamily: 'Quicksand_700Bold',
                          fontSize: { minWidth: Breakpoints.Tablet, value: 18 },
                          marginBottom: 10,
                          marginTop: 10,
                          textAlign: 'left',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Home'}
                  />
                  {/* Button 3 */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'hamburger_nav',
                          value: false,
                        });
                        navigation.navigate('AboutScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          color: theme.colors.text.strong,
                          fontFamily: 'Quicksand_400Regular',
                          fontSize: { minWidth: Breakpoints.Tablet, value: 18 },
                          marginBottom: 10,
                          marginTop: 10,
                          textAlign: 'left',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'About'}
                  />
                  {/* Button 3 2 */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'hamburger_nav',
                          value: false,
                        });
                        navigation.navigate('CompanyOwnersScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          color: theme.colors.text.strong,
                          fontFamily: 'Quicksand_400Regular',
                          fontSize: { minWidth: Breakpoints.Tablet, value: 18 },
                          marginBottom: 10,
                          marginTop: 10,
                          textAlign: 'left',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'For company owners'}
                  />
                  {/* Button 4 */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'hamburger_nav',
                          value: false,
                        });
                        navigation.navigate('ContactScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          color: theme.colors.text.strong,
                          fontFamily: 'Quicksand_400Regular',
                          fontSize: { minWidth: Breakpoints.Tablet, value: 18 },
                          marginBottom: 10,
                          marginTop: 10,
                          textAlign: 'left',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Contact Us'}
                  />
                  {/* Button 5 */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'hamburger_nav',
                          value: false,
                        });
                        navigation.navigate('ScheduleDemoScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          color: theme.colors.text.strong,
                          fontFamily: 'Quicksand_400Regular',
                          fontSize: { minWidth: Breakpoints.Tablet, value: 18 },
                          marginBottom: 10,
                          marginTop: 10,
                          textAlign: 'left',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Schedule Demo'}
                  />
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'hamburger_nav',
                          value: false,
                        });
                        setGlobalVariableValue({
                          key: 'login_options_mobile',
                          value: true,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderColor: theme.colors.text.strong,
                          borderRadius: 5,
                          borderWidth: 1,
                          color: theme.colors.text.strong,
                          fontFamily: 'Quicksand_500Medium',
                          fontSize: { minWidth: Breakpoints.Tablet, value: 18 },
                          marginTop: 40,
                          maxWidth: 350,
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Log In'}
                  />
                </VStack>
              </View>
            </Modal>
          )}
        </>
        {/* Modal 2 */}
        <>
          {dimensions.width >= Breakpoints.Laptop ? null : (
            <Modal
              animationType={'none'}
              supportedOrientations={['portrait', 'landscape']}
              presentationStyle={'pageSheet'}
              transparent={true}
              visible={Constants['login_options_mobile']}
            >
              <View
                style={StyleSheet.applyWidth(
                  { backgroundColor: theme.colors.background.brand },
                  dimensions.width
                )}
              >
                <HStack
                  {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.HStackStyles(theme)['H Stack'].style,
                      {
                        justifyContent: 'space-between',
                        marginLeft: 20,
                        marginRight: 20,
                        marginTop: 15,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('HomeScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <HStack
                      {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.HStackStyles(theme)['H Stack'].style,
                          {
                            alignItems: [
                              {
                                minWidth: Breakpoints.Laptop,
                                value: 'flex-end',
                              },
                              {
                                minWidth: Breakpoints.Mobile,
                                value: 'flex-end',
                              },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      <Image
                        resizeMode={'cover'}
                        {...GlobalStyles.ImageStyles(theme)['Image'].props}
                        source={imageSource(
                          Images['mainsightsfaviconlogo10243']
                        )}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ImageStyles(theme)['Image'].style,
                            { height: 30, marginRight: 5, width: 30 }
                          ),
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Section heading']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Section heading']
                              .style,
                            {
                              color: palettes.App.Orange,
                              fontFamily: 'Poppins_900Black',
                              lineHeight: 14,
                              paddingBottom: 0,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'M&A INSIGHTS'}
                      </Text>
                    </HStack>
                  </Touchable>
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'login_options_mobile',
                          value: false,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    icon={'Ionicons/menu-sharp'}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          color: theme.colors.text.strong,
                        }
                      ),
                      dimensions.width
                    )}
                    title={''}
                  />
                </HStack>

                <VStack
                  {...GlobalStyles.VStackStyles(theme)['V Stack'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.VStackStyles(theme)['V Stack'].style,
                      {
                        alignItems: {
                          minWidth: Breakpoints.Tablet,
                          value: 'center',
                        },
                        alignSelf: {
                          minWidth: Breakpoints.Tablet,
                          value: 'center',
                        },
                        marginBottom: 25,
                        marginLeft: 20,
                        marginRight: 20,
                        marginTop: 25,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Section heading'].style,
                        {
                          fontFamily: 'Quicksand_400Regular',
                          marginBottom: {
                            minWidth: Breakpoints.Tablet,
                            value: 30,
                          },
                          textAlign: {
                            minWidth: Breakpoints.Tablet,
                            value: 'center',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {
                      'M&A Insights from NKP is available both as a web app and in the App app store. For reading the daily newsletters we recommend using the iOS app if you access from an iPhone of iPad.'
                    }
                  </Text>
                  {/* Button 4 */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        Linking.openURL('https://app.mainsights.io/');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    icon={'MaterialCommunityIcons/web'}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderRadius: 5,
                          borderWidth: 1,
                          color: theme.colors.text.strong,
                          fontFamily: 'Quicksand_700Bold',
                          marginBottom: 10,
                          marginTop: 20,
                          maxWidth: {
                            minWidth: Breakpoints.Tablet,
                            value: 300,
                          },
                          textAlign: 'left',
                          width: { minWidth: Breakpoints.Tablet, value: 300 },
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Log in to web version'}
                  />
                  {/* Button 5 */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          await WebBrowser.openBrowserAsync(
                            'https://apps.apple.com/us/app/m-a-insights/id6670381982'
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    icon={'AntDesign/apple1'}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderRadius: 5,
                          borderWidth: 1,
                          color: theme.colors.text.strong,
                          fontFamily: 'Quicksand_700Bold',
                          marginBottom: 10,
                          marginTop: 20,
                          maxWidth: {
                            minWidth: Breakpoints.Tablet,
                            value: 300,
                          },
                          textAlign: 'left',
                          width: { minWidth: Breakpoints.Tablet, value: 300 },
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Download in App Store'}
                  />
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Section heading'].style,
                        {
                          fontFamily: 'Quicksand_400Regular',
                          marginTop: 20,
                          textAlign: {
                            minWidth: Breakpoints.Tablet,
                            value: 'center',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Web version: https://app.mainsights.io/'}
                  </Text>
                  {/* Text 3 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Section heading'].style,
                        {
                          fontFamily: 'Quicksand_400Regular',
                          marginTop: 20,
                          textAlign: {
                            minWidth: Breakpoints.Tablet,
                            value: 'center',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {
                      'App store: https://apps.apple.com/us/app/m-a-insights/id6670381982'
                    }
                  </Text>
                </VStack>
              </View>
            </Modal>
          )}
        </>
        {/* Modal 2 2 */}
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <Modal
              animationType={'none'}
              supportedOrientations={['portrait', 'landscape']}
              presentationStyle={'pageSheet'}
              transparent={true}
              visible={Constants['login_options_laptop']}
            >
              <View
                style={StyleSheet.applyWidth(
                  { backgroundColor: theme.colors.background.brand },
                  dimensions.width
                )}
              >
                <HStack
                  {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.HStackStyles(theme)['H Stack'].style,
                      {
                        justifyContent: 'space-between',
                        marginLeft: 20,
                        marginRight: 20,
                        marginTop: 15,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('HomeScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <HStack
                      {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.HStackStyles(theme)['H Stack'].style,
                          {
                            alignItems: [
                              {
                                minWidth: Breakpoints.Laptop,
                                value: 'flex-end',
                              },
                              {
                                minWidth: Breakpoints.Mobile,
                                value: 'flex-end',
                              },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      <Image
                        resizeMode={'cover'}
                        {...GlobalStyles.ImageStyles(theme)['Image'].props}
                        source={imageSource(
                          Images['mainsightsfaviconlogo10243']
                        )}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ImageStyles(theme)['Image'].style,
                            { height: 30, marginRight: 5, width: 30 }
                          ),
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Section heading']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Section heading']
                              .style,
                            {
                              color: palettes.App.Orange,
                              fontFamily: 'Poppins_900Black',
                              lineHeight: 14,
                              paddingBottom: 0,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'M&A INSIGHTS'}
                      </Text>
                    </HStack>
                  </Touchable>
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'login_options_laptop',
                          value: false,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    icon={'AntDesign/closecircleo'}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          color: theme.colors.text.strong,
                        }
                      ),
                      dimensions.width
                    )}
                    title={''}
                  />
                </HStack>

                <VStack
                  {...GlobalStyles.VStackStyles(theme)['V Stack'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.VStackStyles(theme)['V Stack'].style,
                      {
                        alignContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        alignItems: [
                          { minWidth: Breakpoints.Tablet, value: 'center' },
                          { minWidth: Breakpoints.Laptop, value: 'center' },
                        ],
                        alignSelf: {
                          minWidth: Breakpoints.Tablet,
                          value: 'center',
                        },
                        marginBottom: 25,
                        marginLeft: 20,
                        marginRight: 20,
                        marginTop: 25,
                        maxWidth: [
                          { minWidth: Breakpoints.Laptop, value: '75%' },
                          { minWidth: Breakpoints.BigScreen, value: '50%' },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <VStack
                    {...GlobalStyles.VStackStyles(theme)['V Stack'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.VStackStyles(theme)['V Stack'].style,
                        {
                          alignItems: {
                            minWidth: Breakpoints.Tablet,
                            value: 'center',
                          },
                          alignSelf: {
                            minWidth: Breakpoints.Tablet,
                            value: 'center',
                          },
                          marginBottom: 25,
                          marginLeft: 20,
                          marginRight: 20,
                          marginTop: 25,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Section heading']
                        .props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Section heading']
                            .style,
                          {
                            fontFamily: 'Quicksand_400Regular',
                            marginBottom: {
                              minWidth: Breakpoints.Tablet,
                              value: 30,
                            },
                            textAlign: {
                              minWidth: Breakpoints.Tablet,
                              value: 'center',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        'M&A Insights from NKP is available both as a web app and in the App app store. For reading the daily newsletters we recommend using the iOS app if you access from an iPhone of iPad.'
                      }
                    </Text>
                    {/* Button 4 */}
                    <Button
                      iconPosition={'left'}
                      onPress={() => {
                        try {
                          Linking.openURL('https://app.mainsights.io/');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                      icon={'MaterialCommunityIcons/web'}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'].style,
                          {
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            borderRadius: 5,
                            borderWidth: 1,
                            color: theme.colors.text.strong,
                            fontFamily: 'Quicksand_700Bold',
                            marginBottom: 10,
                            marginTop: 20,
                            maxWidth: {
                              minWidth: Breakpoints.Tablet,
                              value: 300,
                            },
                            textAlign: 'left',
                            width: { minWidth: Breakpoints.Tablet, value: 300 },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Log in to web version'}
                    />
                    {/* Button 5 */}
                    <Button
                      iconPosition={'left'}
                      onPress={() => {
                        const handler = async () => {
                          try {
                            await WebBrowser.openBrowserAsync(
                              'https://apps.apple.com/us/app/m-a-insights/id6670381982'
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                      icon={'AntDesign/apple1'}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'].style,
                          {
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            borderRadius: 5,
                            borderWidth: 1,
                            color: theme.colors.text.strong,
                            fontFamily: 'Quicksand_700Bold',
                            marginBottom: 10,
                            marginTop: 20,
                            maxWidth: {
                              minWidth: Breakpoints.Tablet,
                              value: 300,
                            },
                            textAlign: 'left',
                            width: { minWidth: Breakpoints.Tablet, value: 300 },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Download in App Store'}
                    />
                    {/* Text 2 */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Section heading']
                        .props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Section heading']
                            .style,
                          {
                            fontFamily: 'Quicksand_400Regular',
                            marginTop: 20,
                            textAlign: {
                              minWidth: Breakpoints.Tablet,
                              value: 'center',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Web version: https://app.mainsights.io/'}
                    </Text>
                    {/* Text 3 */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Section heading']
                        .props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Section heading']
                            .style,
                          {
                            fontFamily: 'Quicksand_400Regular',
                            marginTop: 20,
                            textAlign: {
                              minWidth: Breakpoints.Tablet,
                              value: 'center',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        'App store: https://apps.apple.com/us/app/m-a-insights/id6670381982'
                      }
                    </Text>
                  </VStack>
                </VStack>
              </View>
            </Modal>
          )}
        </>
      </LinearGradient>
      {/* Linear Gradient 3 */}
      <LinearGradient
        endX={100}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Primary gradient'].props}
        color1={theme.colors.branding.primary}
        color2={theme.colors.text.strong}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.LinearGradientStyles(theme)['Primary gradient'].style,
            {
              alignContent: {
                minWidth: Breakpoints.Laptop,
                value: 'flex-start',
              },
              alignItems: 'center',
            }
          ),
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: [
                { minWidth: Breakpoints.Laptop, value: 'center' },
                { minWidth: Breakpoints.Mobile, value: 'center' },
              ],
              marginBottom: 45,
              marginTop: 45,
              maxWidth: [
                { minWidth: Breakpoints.Laptop, value: '75%' },
                { minWidth: Breakpoints.Desktop, value: '60%' },
              ],
            },
            dimensions.width
          )}
        >
          {/* Text 3 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Hero sub heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Hero sub heading'].style,
                {
                  color: theme.colors.background.brand,
                  fontFamily: 'Quicksand_700Bold',
                  fontSize: [
                    { minWidth: Breakpoints.Mobile, value: 18 },
                    { minWidth: Breakpoints.Laptop, value: 18 },
                    { minWidth: Breakpoints.Desktop, value: 24 },
                  ],
                  marginBottom: 20,
                  marginLeft: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 45 },
                  ],
                  marginRight: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 45 },
                  ],
                  marginTop: 15,
                  textAlign: 'center',
                }
              ),
              dimensions.width
            )}
          >
            {'What is M&A Insights?'}
          </Text>
          <Image
            resizeMode={'cover'}
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            source={imageSource(Images['Puzzle1'])}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                {
                  marginBottom: 20,
                  marginTop: [
                    { minWidth: Breakpoints.Mobile, value: 10 },
                    { minWidth: Breakpoints.Laptop, value: 20 },
                  ],
                  opacity: { minWidth: Breakpoints.Laptop, value: 1 },
                  position: [
                    { minWidth: Breakpoints.Mobile, value: 'relative' },
                    { minWidth: Breakpoints.Laptop, value: 'relative' },
                  ],
                }
              ),
              dimensions.width
            )}
          />
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['section body'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['section body'].style,
                {
                  fontFamily: {
                    minWidth: Breakpoints.Desktop,
                    value: 'Quicksand_400Regular',
                  },
                }
              ),
              dimensions.width
            )}
          >
            {
              'We create visibility in the M&A market, enabling our clients to reliably track what is moving here and now and equally important - what are the assets coming to market in the next 3-18 months based on information not available elsewhere.'
            }
          </Text>
        </View>
        {/* View 6 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: 'center',
              alignItems: [
                { minWidth: Breakpoints.Mobile, value: 'center' },
                { minWidth: Breakpoints.Laptop, value: 'center' },
              ],
              alignSelf: [
                { minWidth: Breakpoints.Mobile, value: 'stretch' },
                { minWidth: Breakpoints.Laptop, value: 'center' },
              ],
              justifyContent: 'center',
              maxWidth: { minWidth: Breakpoints.Laptop, value: '75%' },
              paddingLeft: { minWidth: Breakpoints.Tablet, value: 40 },
              paddingRight: { minWidth: Breakpoints.Tablet, value: 40 },
              width: { minWidth: Breakpoints.Laptop, value: '75%' },
            },
            dimensions.width
          )}
        >
          {/* View 3 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'flex-start',
                alignSelf: 'flex-start',
                marginBottom: 50,
                marginLeft: 20,
              },
              dimensions.width
            )}
          >
            <HStack
              {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.HStackStyles(theme)['H Stack'].style,
                dimensions.width
              )}
            >
              <Image
                resizeMode={'cover'}
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                source={imageSource(Images['Newsletter'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { height: 50, marginRight: 20, width: 50 }
                  ),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_400Regular',
                      fontSize: { minWidth: Breakpoints.Laptop, value: 18 },
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Daily unique non-public intelligence'}
              </Text>
            </HStack>
          </View>
          {/* View 4 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: 'flex-end',
                alignItems: 'flex-end',
                alignSelf: 'flex-end',
                marginBottom: 50,
                marginLeft: 20,
                marginRight: 20,
              },
              dimensions.width
            )}
          >
            {/* H Stack 2 */}
            <HStack
              {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.HStackStyles(theme)['H Stack'].style,
                  { justifyContent: 'flex-end' }
                ),
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_400Regular',
                      fontSize: { minWidth: Breakpoints.Laptop, value: 18 },
                    }
                  ),
                  dimensions.width
                )}
              >
                {'SaaS platform with proprietary datasets'}
              </Text>
              <Image
                resizeMode={'cover'}
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                source={imageSource(Images['CloudData'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { height: 50, marginLeft: 20, width: 50 }
                  ),
                  dimensions.width
                )}
              />
            </HStack>
          </View>
          {/* View 5 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: 'flex-start',
                alignItems: 'flex-start',
                alignSelf: 'flex-start',
                marginBottom: 40,
                marginLeft: 20,
                marginRight: 20,
              },
              dimensions.width
            )}
          >
            {/* H Stack 2 */}
            <HStack
              {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.HStackStyles(theme)['H Stack'].style,
                  { justifyContent: 'flex-end' }
                ),
                dimensions.width
              )}
            >
              <Image
                resizeMode={'cover'}
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                source={imageSource(Images['Networking'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { height: 50, marginRight: 20, width: 50 }
                  ),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_400Regular',
                      fontSize: { minWidth: Breakpoints.Laptop, value: 18 },
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Tailored one-on-one briefings'}
              </Text>
            </HStack>
          </View>
          <Image
            resizeMode={'cover'}
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            source={imageSource(Images['BuildingBlocks'])}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                {
                  height: '100%',
                  opacity: 0.18,
                  position: 'absolute',
                  width: 300,
                }
              ),
              dimensions.width
            )}
          />
        </View>
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: { minWidth: Breakpoints.Laptop, value: 'center' },
              alignItems: 'center',
              marginBottom: 45,
              marginTop: 45,
              maxWidth: [
                { minWidth: Breakpoints.Laptop, value: '75%' },
                { minWidth: Breakpoints.BigScreen, value: '50%' },
                { minWidth: Breakpoints.Desktop, value: '60%' },
              ],
            },
            dimensions.width
          )}
        >
          <HStack
            {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.HStackStyles(theme)['H Stack'].style,
                { flexDirection: 'column', flexWrap: 'wrap' }
              ),
              dimensions.width
            )}
          >
            <VStack
              {...GlobalStyles.VStackStyles(theme)['V Stack'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.VStackStyles(theme)['V Stack'].style,
                  {
                    alignItems: 'flex-start',
                    borderColor: theme.colors.background.danger,
                    borderRadius: 5,
                    borderWidth: 1,
                    flexWrap: 'wrap',
                    marginBottom: [
                      { minWidth: Breakpoints.Tablet, value: 20 },
                      { minWidth: Breakpoints.Mobile, value: 20 },
                    ],
                    marginLeft: 20,
                    marginRight: 20,
                    paddingBottom: 10,
                    paddingTop: 10,
                  }
                ),
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_700Bold',
                      marginLeft: 10,
                      marginRight: 10,
                      textAlign: 'left',
                    }
                  ),
                  dimensions.width
                )}
              >
                {
                  '"Pitch of the Day" deep-dives portray two new named opportunities, every weekday'
                }
              </Text>
              <>
                {dimensions.width >= Breakpoints.Laptop ? null : (
                  <Image
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    resizeMode={'contain'}
                    source={imageSource(Images['img3067portrait'])}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'].style,
                        {
                          height: 350,
                          marginBottom: 10,
                          marginLeft: 10,
                          marginRight: 10,
                          marginTop: 10,
                          opacity: 0.8,
                          width: 200,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                )}
              </>
              {/* Image 2 */}
              <>
                {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                  <Image
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    resizeMode={'contain'}
                    source={imageSource(
                      Images['laptopscreenshotnewslettersfront']
                    )}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'].style,
                        {
                          height: [
                            { minWidth: Breakpoints.Laptop, value: 400 },
                            { minWidth: Breakpoints.Desktop, value: 500 },
                          ],
                          width: [
                            { minWidth: Breakpoints.Laptop, value: 500 },
                            { minWidth: Breakpoints.Desktop, value: 700 },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                  />
                )}
              </>
              {/* Text 2 */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      alignSelf: 'center',
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_400Regular',
                      marginLeft: 10,
                      marginRight: 10,
                      textAlign: 'left',
                    }
                  ),
                  dimensions.width
                )}
              >
                {
                  'Each day in our newsletter we cover one Nordic and one DACH-based named opportunity, always based on non-public sources and with rich details on target performance and the process.'
                }
              </Text>
            </VStack>
            {/* V Stack 2 */}
            <VStack
              {...GlobalStyles.VStackStyles(theme)['V Stack'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.VStackStyles(theme)['V Stack'].style,
                  {
                    alignItems: 'flex-start',
                    borderColor: theme.colors.background.danger,
                    borderRadius: 5,
                    borderWidth: 1,
                    flexWrap: 'wrap',
                    marginBottom: [
                      { minWidth: Breakpoints.Tablet, value: 20 },
                      { minWidth: Breakpoints.Mobile, value: 20 },
                    ],
                    marginLeft: 20,
                    marginRight: 20,
                    paddingBottom: 10,
                    paddingTop: 10,
                  }
                ),
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_700Bold',
                      marginLeft: 10,
                      marginRight: 10,
                      textAlign: 'left',
                    }
                  ),
                  dimensions.width
                )}
              >
                {
                  'Highly curated M&A newsflow based on non-public and public sources in executive summary format'
                }
              </Text>
              <>
                {dimensions.width >= Breakpoints.Laptop ? null : (
                  <Image
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    resizeMode={'contain'}
                    source={imageSource(Images['img3068portrait'])}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'].style,
                        {
                          height: 350,
                          marginBottom: 10,
                          marginLeft: 10,
                          marginRight: 10,
                          marginTop: 10,
                          width: 200,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                )}
              </>
              {/* Image 3 */}
              <>
                {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                  <Image
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    resizeMode={'contain'}
                    source={imageSource(Images['laptopscreeshotdachnlfront'])}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'].style,
                        {
                          height: [
                            { minWidth: Breakpoints.Laptop, value: 400 },
                            { minWidth: Breakpoints.Desktop, value: 500 },
                          ],
                          width: [
                            { minWidth: Breakpoints.Laptop, value: 500 },
                            { minWidth: Breakpoints.Desktop, value: 700 },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                  />
                )}
              </>
              {/* Text 2 */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      alignSelf: 'center',
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_400Regular',
                      marginLeft: 10,
                      marginRight: 10,
                      textAlign: 'left',
                    }
                  ),
                  dimensions.width
                )}
              >
                {
                  'Based on a combination of proprietary and public sources, our daily newsletter contain the region-specific insights and newsflow in an enriched and highly structured executive summary format.'
                }
              </Text>
            </VStack>
            {/* V Stack 3 */}
            <VStack
              {...GlobalStyles.VStackStyles(theme)['V Stack'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.VStackStyles(theme)['V Stack'].style,
                  {
                    alignItems: 'flex-start',
                    borderColor: theme.colors.background.danger,
                    borderRadius: 5,
                    borderWidth: 1,
                    flexWrap: 'wrap',
                    marginBottom: [
                      { minWidth: Breakpoints.Mobile, value: 20 },
                      { minWidth: Breakpoints.Tablet, value: 20 },
                    ],
                    marginLeft: 20,
                    marginRight: 20,
                    paddingBottom: 10,
                    paddingTop: 10,
                  }
                ),
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_700Bold',
                      marginLeft: 10,
                      marginRight: 10,
                      textAlign: 'left',
                    }
                  ),
                  dimensions.width
                )}
              >
                {
                  'Companies for Sale and other proprietary datasets to empower dealsourcing'
                }
              </Text>
              <>
                {dimensions.width >= Breakpoints.Laptop ? null : (
                  <Image
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    resizeMode={'contain'}
                    source={imageSource(Images['img3072portrait'])}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'].style,
                        {
                          height: 350,
                          marginBottom: 10,
                          marginLeft: 10,
                          marginRight: 10,
                          marginTop: 10,
                          width: 200,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                )}
              </>
              {/* Image 4 */}
              <>
                {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                  <Image
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    resizeMode={'contain'}
                    source={imageSource(
                      Images['laptopscreenshotcfswithfiltersfront']
                    )}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'].style,
                        {
                          height: [
                            { minWidth: Breakpoints.Laptop, value: 400 },
                            { minWidth: Breakpoints.Desktop, value: 500 },
                          ],
                          width: [
                            { minWidth: Breakpoints.Laptop, value: 500 },
                            { minWidth: Breakpoints.Desktop, value: 700 },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                  />
                )}
              </>
              {/* Image 2 */}
              <>
                {dimensions.width >= Breakpoints.Laptop ? null : (
                  <Image
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    resizeMode={'contain'}
                    source={imageSource(Images['img3074portrait'])}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'].style,
                        {
                          height: 350,
                          marginBottom: 10,
                          marginLeft: 10,
                          marginRight: 10,
                          marginTop: 10,
                          width: 200,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                )}
              </>
              {/* Image 5 */}
              <>
                {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                  <Image
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    resizeMode={'contain'}
                    source={imageSource(
                      Images['laptopscreenshotpepfwithfilterfront']
                    )}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'].style,
                        {
                          height: [
                            { minWidth: Breakpoints.Laptop, value: 400 },
                            { minWidth: Breakpoints.Desktop, value: 500 },
                          ],
                          width: [
                            { minWidth: Breakpoints.Laptop, value: 500 },
                            { minWidth: Breakpoints.Desktop, value: 700 },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                  />
                )}
              </>
              {/* Text 2 */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      alignSelf: 'center',
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_400Regular',
                      marginLeft: 10,
                      marginRight: 10,
                      textAlign: 'left',
                    }
                  ),
                  dimensions.width
                )}
              >
                {
                  'Our cloud platform is packed with all of our historical research in an easily queryable format and several high-value proprietary datasets such as our Companies for Sale index and a complete dynamic mapping of the private equity ecosystem.'
                }
              </Text>
            </VStack>
            {/* V Stack 4 */}
            <VStack
              {...GlobalStyles.VStackStyles(theme)['V Stack'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.VStackStyles(theme)['V Stack'].style,
                  {
                    alignItems: 'flex-start',
                    borderColor: theme.colors.background.danger,
                    borderRadius: 5,
                    borderWidth: 1,
                    flexWrap: 'wrap',
                    marginBottom: [
                      { minWidth: Breakpoints.Tablet, value: 20 },
                      { minWidth: Breakpoints.Mobile, value: 20 },
                    ],
                    marginLeft: 20,
                    marginRight: 20,
                    paddingBottom: 10,
                    paddingTop: 10,
                  }
                ),
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_700Bold',
                      marginLeft: 10,
                      marginRight: 10,
                      textAlign: 'left',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'M&A-centric stock analytics and peer groups'}
              </Text>
              <>
                {dimensions.width >= Breakpoints.Laptop ? null : (
                  <Image
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    resizeMode={'contain'}
                    source={imageSource(Images['img3076portrait'])}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'].style,
                        {
                          height: 350,
                          marginBottom: 10,
                          marginLeft: 10,
                          marginRight: 10,
                          marginTop: 10,
                          width: 200,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                )}
              </>
              {/* Image 6 */}
              <>
                {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                  <Image
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    resizeMode={'contain'}
                    source={imageSource(
                      Images['laptopscreenshotpeergroupsnofilterfront']
                    )}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'].style,
                        {
                          height: [
                            { minWidth: Breakpoints.Laptop, value: 400 },
                            { minWidth: Breakpoints.Desktop, value: 500 },
                          ],
                          width: [
                            { minWidth: Breakpoints.Laptop, value: 500 },
                            { minWidth: Breakpoints.Desktop, value: 700 },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                  />
                )}
              </>
              {/* Text 2 */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      alignSelf: 'center',
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_400Regular',
                      marginLeft: 10,
                      marginRight: 10,
                      textAlign: 'left',
                    }
                  ),
                  dimensions.width
                )}
              >
                {
                  "Our no-thrills M&A-centric stock analysis tool is packed with commercial-grade data and analyst forecasts for more than 20,000 tickers and gives you access to the library of handpicked peer groups that we've carefully put together for each (private market) target covered in our newsfeed."
                }
              </Text>
            </VStack>
          </HStack>
        </View>
      </LinearGradient>
      {/* Linear Gradient 4 */}
      <LinearGradient
        endX={100}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Primary gradient'].props}
        color1={theme.colors.branding.primary}
        color2={theme.colors.text.strong}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.LinearGradientStyles(theme)['Primary gradient'].style,
            { alignItems: 'center' }
          ),
          dimensions.width
        )}
      >
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: { minWidth: Breakpoints.Laptop, value: 'center' },
              alignItems: 'center',
              marginBottom: 45,
              marginTop: 45,
              maxWidth: [
                { minWidth: Breakpoints.Laptop, value: '75%' },
                { minWidth: Breakpoints.BigScreen, value: '50%' },
                { minWidth: Breakpoints.Desktop, value: '60%' },
              ],
            },
            dimensions.width
          )}
        >
          {/* Text 3 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Hero sub heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Hero sub heading'].style,
                {
                  color: theme.colors.background.brand,
                  fontFamily: 'Quicksand_700Bold',
                  fontSize: [
                    { minWidth: Breakpoints.Laptop, value: 18 },
                    { minWidth: Breakpoints.Mobile, value: 18 },
                    { minWidth: Breakpoints.Desktop, value: 24 },
                  ],
                  marginBottom: 20,
                  marginLeft: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 45 },
                  ],
                  marginRight: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 45 },
                  ],
                  marginTop: 15,
                  textAlign: 'center',
                }
              ),
              dimensions.width
            )}
          >
            {'5-10x more cases covered based on non-public sources'}
          </Text>
          <Image
            resizeMode={'cover'}
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            source={imageSource(Images['Funnel'])}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                { marginBottom: 25 }
              ),
              dimensions.width
            )}
          />
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['section body'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['section body'].style,
                {
                  fontFamily: [
                    {
                      minWidth: Breakpoints.Desktop,
                      value: 'Quicksand_400Regular',
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: 'Quicksand_400Regular',
                    },
                  ],
                }
              ),
              dimensions.width
            )}
          >
            {
              'Compared with any other information or data provider, we share light on 5-10x as many cases based on non-public information across size tiers and sectors for the markets that we cover.'
            }
          </Text>
        </View>
      </LinearGradient>
      {/* Linear Gradient 6 */}
      <LinearGradient
        endX={100}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Primary gradient'].props}
        color1={palettes.App.Purple}
        color2={theme.colors.text.strong}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.LinearGradientStyles(theme)['Primary gradient'].style,
            { alignItems: 'center' }
          ),
          dimensions.width
        )}
      >
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: { minWidth: Breakpoints.Laptop, value: 'center' },
              alignItems: 'center',
              marginBottom: 45,
              marginTop: 45,
              maxWidth: [
                { minWidth: Breakpoints.Laptop, value: '75%' },
                { minWidth: Breakpoints.BigScreen, value: '50%' },
                { minWidth: Breakpoints.Desktop, value: '60%' },
              ],
            },
            dimensions.width
          )}
        >
          {/* Text 3 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Hero sub heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Hero sub heading'].style,
                {
                  color: theme.colors.background.brand,
                  fontFamily: 'Quicksand_700Bold',
                  fontSize: [
                    { minWidth: Breakpoints.Laptop, value: 18 },
                    { minWidth: Breakpoints.Mobile, value: 18 },
                    { minWidth: Breakpoints.Desktop, value: 24 },
                  ],
                  marginBottom: 20,
                  marginLeft: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 45 },
                  ],
                  marginRight: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 45 },
                  ],
                  marginTop: 15,
                  textAlign: 'center',
                }
              ),
              dimensions.width
            )}
          >
            {
              'Pre-process coverage enables clients to turn insights into action'
            }
          </Text>
          <Image
            resizeMode={'cover'}
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            source={imageSource(Images['Roadmap2'])}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                { marginBottom: 25 }
              ),
              dimensions.width
            )}
          />
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['section body'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['section body'].style,
                {
                  fontFamily: [
                    {
                      minWidth: Breakpoints.Desktop,
                      value: 'Quicksand_400Regular',
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: 'Quicksand_400Regular',
                    },
                  ],
                }
              ),
              dimensions.width
            )}
          >
            {
              'Based on systematic, proactive dialogue with key stakeholders we strive to, and most often succeed in, covering new opportunities at a very early stage, giving our clients amble time to prepare.'
            }
          </Text>
        </View>
      </LinearGradient>
      {/* Linear Gradient 5 */}
      <LinearGradient
        endX={100}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Primary gradient'].props}
        color1={theme.colors.branding.primary}
        color2={theme.colors.text.strong}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.LinearGradientStyles(theme)['Primary gradient'].style,
            { alignItems: 'center' }
          ),
          dimensions.width
        )}
      >
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: { minWidth: Breakpoints.Laptop, value: 'center' },
              alignItems: 'center',
              marginBottom: 45,
              marginTop: 45,
              maxWidth: [
                { minWidth: Breakpoints.Laptop, value: '75%' },
                { minWidth: Breakpoints.BigScreen, value: '50%' },
                { minWidth: Breakpoints.Desktop, value: '60%' },
              ],
            },
            dimensions.width
          )}
        >
          {/* Text 3 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Hero sub heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Hero sub heading'].style,
                {
                  color: theme.colors.background.brand,
                  fontFamily: 'Quicksand_700Bold',
                  fontSize: [
                    { minWidth: Breakpoints.Laptop, value: 18 },
                    { minWidth: Breakpoints.Desktop, value: 24 },
                    { minWidth: Breakpoints.Mobile, value: 18 },
                  ],
                  marginBottom: 20,
                  marginLeft: [
                    { minWidth: Breakpoints.Laptop, value: 45 },
                    { minWidth: Breakpoints.Mobile, value: 20 },
                  ],
                  marginRight: [
                    { minWidth: Breakpoints.Laptop, value: 45 },
                    { minWidth: Breakpoints.Mobile, value: 20 },
                  ],
                  marginTop: 15,
                  textAlign: 'center',
                }
              ),
              dimensions.width
            )}
          >
            {'Access to the best M&A intelligence, anywhere and any time'}
          </Text>

          <HStack
            {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.HStackStyles(theme)['H Stack'].style,
                {
                  marginBottom: [
                    { minWidth: Breakpoints.Mobile, value: 25 },
                    { minWidth: Breakpoints.Tablet, value: 40 },
                  ],
                  marginTop: [
                    { minWidth: Breakpoints.Tablet, value: 40 },
                    { minWidth: Breakpoints.Mobile, value: 25 },
                  ],
                }
              ),
              dimensions.width
            )}
          >
            {/* Icon 2 */}
            <Icon
              color={theme.colors.background.brand}
              name={'MaterialIcons/phone-iphone'}
              size={50}
              style={StyleSheet.applyWidth(
                { marginRight: 45 },
                dimensions.width
              )}
            />
            {/* Icon 3 */}
            <Icon
              color={theme.colors.background.brand}
              name={'AntDesign/tablet1'}
              size={50}
              style={StyleSheet.applyWidth(
                { marginRight: 45 },
                dimensions.width
              )}
            />
            <Icon
              color={theme.colors.background.brand}
              name={'FontAwesome/laptop'}
              size={50}
            />
          </HStack>
          {/* Text 2 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['section body'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['section body'].style,
                {
                  fontFamily: [
                    {
                      minWidth: Breakpoints.Desktop,
                      value: 'Quicksand_400Regular',
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: 'Quicksand_400Regular',
                    },
                  ],
                }
              ),
              dimensions.width
            )}
          >
            {
              'With a personal login, you can access all of our research and insights through a desktop browser and through our mobile and tablet applications available in the app stores.'
            }
          </Text>
          {/* H Stack 2 */}
          <HStack
            {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.HStackStyles(theme)['H Stack'].style,
                {
                  flexWrap: 'wrap',
                  marginBottom: [
                    { minWidth: Breakpoints.Tablet, value: 40 },
                    { minWidth: Breakpoints.Mobile, value: 25 },
                  ],
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: [
                    { minWidth: Breakpoints.Tablet, value: 40 },
                    { minWidth: Breakpoints.Mobile, value: 25 },
                  ],
                }
              ),
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  borderColor: theme.colors.background.brand,
                  borderRadius: 5,
                  borderWidth: 1,
                  marginBottom: 10,
                  marginLeft: 5,
                  marginRight: 5,
                  marginTop: 10,
                  paddingBottom: 5,
                  paddingLeft: 5,
                  paddingRight: 5,
                  paddingTop: 5,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_300Light',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Companies for Sale'}
              </Text>
            </View>
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderColor: theme.colors.background.brand,
                  borderRadius: 5,
                  borderWidth: 1,
                  marginBottom: 10,
                  marginLeft: 5,
                  marginRight: 5,
                  marginTop: 10,
                  paddingBottom: 5,
                  paddingLeft: 5,
                  paddingRight: 5,
                  paddingTop: 5,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_300Light',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Portfolio Firms'}
              </Text>
            </View>
            {/* View 3 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderColor: theme.colors.background.brand,
                  borderRadius: 5,
                  borderWidth: 1,
                  marginBottom: 10,
                  marginLeft: 5,
                  marginRight: 5,
                  marginTop: 10,
                  paddingBottom: 5,
                  paddingLeft: 5,
                  paddingRight: 5,
                  paddingTop: 5,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_300Light',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Transactions'}
              </Text>
            </View>
            {/* View 4 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderColor: theme.colors.background.brand,
                  borderRadius: 5,
                  borderWidth: 1,
                  marginBottom: 10,
                  marginLeft: 5,
                  marginRight: 5,
                  marginTop: 10,
                  paddingBottom: 5,
                  paddingLeft: 5,
                  paddingRight: 5,
                  paddingTop: 5,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_300Light',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Multiples'}
              </Text>
            </View>
            {/* View 5 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderColor: theme.colors.background.brand,
                  borderRadius: 5,
                  borderWidth: 1,
                  marginBottom: 10,
                  marginLeft: 5,
                  marginRight: 5,
                  marginTop: 10,
                  paddingBottom: 5,
                  paddingLeft: 5,
                  paddingRight: 5,
                  paddingTop: 5,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_300Light',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'PE Fund Entities'}
              </Text>
            </View>
            {/* View 6 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderColor: theme.colors.background.brand,
                  borderRadius: 5,
                  borderWidth: 1,
                  marginBottom: 10,
                  marginLeft: 5,
                  marginRight: 5,
                  marginTop: 10,
                  paddingBottom: 5,
                  paddingLeft: 5,
                  paddingRight: 5,
                  paddingTop: 5,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_300Light',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'PE Firms'}
              </Text>
            </View>
            {/* View 7 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderColor: theme.colors.background.brand,
                  borderRadius: 5,
                  borderWidth: 1,
                  marginBottom: 10,
                  marginLeft: 5,
                  marginRight: 5,
                  marginTop: 10,
                  paddingBottom: 5,
                  paddingLeft: 5,
                  paddingRight: 5,
                  paddingTop: 5,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_300Light',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'League Tables'}
              </Text>
            </View>
            {/* View 8 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderColor: theme.colors.background.brand,
                  borderRadius: 5,
                  borderWidth: 1,
                  marginBottom: 10,
                  marginLeft: 5,
                  marginRight: 5,
                  marginTop: 10,
                  paddingBottom: 5,
                  paddingLeft: 5,
                  paddingRight: 5,
                  paddingTop: 5,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_300Light',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Live Processes'}
              </Text>
            </View>
            {/* View 9 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderColor: theme.colors.background.brand,
                  borderRadius: 5,
                  borderWidth: 1,
                  marginBottom: 10,
                  marginLeft: 5,
                  marginRight: 5,
                  marginTop: 10,
                  paddingBottom: 5,
                  paddingLeft: 5,
                  paddingRight: 5,
                  paddingTop: 5,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_300Light',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Coming Processes'}
              </Text>
            </View>
            {/* View 10 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderColor: theme.colors.background.brand,
                  borderRadius: 5,
                  borderWidth: 1,
                  marginBottom: 10,
                  marginLeft: 5,
                  marginRight: 5,
                  marginTop: 10,
                  paddingBottom: 5,
                  paddingLeft: 5,
                  paddingRight: 5,
                  paddingTop: 5,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_300Light',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Paused Processes'}
              </Text>
            </View>
            {/* View 11 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderColor: theme.colors.background.brand,
                  borderRadius: 5,
                  borderWidth: 1,
                  marginBottom: 10,
                  marginLeft: 5,
                  marginRight: 5,
                  marginTop: 10,
                  paddingBottom: 5,
                  paddingLeft: 5,
                  paddingRight: 5,
                  paddingTop: 5,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_300Light',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Primary Opportunities'}
              </Text>
            </View>
            {/* View 12 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderColor: theme.colors.background.brand,
                  borderRadius: 5,
                  borderWidth: 1,
                  marginBottom: 10,
                  marginLeft: 5,
                  marginRight: 5,
                  marginTop: 10,
                  paddingBottom: 5,
                  paddingLeft: 5,
                  paddingRight: 5,
                  paddingTop: 5,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_300Light',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Secondary Opportunities'}
              </Text>
            </View>
            {/* View 13 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderColor: theme.colors.background.brand,
                  borderRadius: 5,
                  borderWidth: 1,
                  marginBottom: 10,
                  marginLeft: 5,
                  marginRight: 5,
                  marginTop: 10,
                  paddingBottom: 5,
                  paddingLeft: 5,
                  paddingRight: 5,
                  paddingTop: 5,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_300Light',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Carve-Outs'}
              </Text>
            </View>
            {/* View 14 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderColor: theme.colors.background.brand,
                  borderRadius: 5,
                  borderWidth: 1,
                  marginBottom: 10,
                  marginLeft: 5,
                  marginRight: 5,
                  marginTop: 10,
                  paddingBottom: 5,
                  paddingLeft: 5,
                  paddingRight: 5,
                  paddingTop: 5,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_300Light',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Stakeholder Sentiments'}
              </Text>
            </View>
            {/* View 15 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderColor: theme.colors.background.brand,
                  borderRadius: 5,
                  borderWidth: 1,
                  marginBottom: 10,
                  marginLeft: 5,
                  marginRight: 5,
                  marginTop: 10,
                  paddingBottom: 5,
                  paddingLeft: 5,
                  paddingRight: 5,
                  paddingTop: 5,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_300Light',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Trading Peer Groups'}
              </Text>
            </View>
            {/* View 16 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderColor: theme.colors.background.brand,
                  borderRadius: 5,
                  borderWidth: 1,
                  marginBottom: 10,
                  marginLeft: 5,
                  marginRight: 5,
                  marginTop: 10,
                  paddingBottom: 5,
                  paddingLeft: 5,
                  paddingRight: 5,
                  paddingTop: 5,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_300Light',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Precedent Comparables'}
              </Text>
            </View>
          </HStack>
        </View>
      </LinearGradient>
      {/* Linear Gradient 7 */}
      <LinearGradient
        endX={100}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Primary gradient'].props}
        color1={theme.colors.text.strong}
        color2={theme.colors.branding.primary}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.LinearGradientStyles(theme)['Primary gradient'].style,
            { alignItems: 'center', paddingBottom: 45 }
          ),
          dimensions.width
        )}
      >
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: { minWidth: Breakpoints.Laptop, value: 'center' },
              alignItems: 'center',
              marginBottom: 30,
              marginTop: 45,
              maxWidth: [
                { minWidth: Breakpoints.Laptop, value: '75%' },
                { minWidth: Breakpoints.BigScreen, value: '50%' },
              ],
            },
            dimensions.width
          )}
        >
          {/* Text 3 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Hero sub heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Hero sub heading'].style,
                {
                  color: theme.colors.background.brand,
                  fontFamily: 'Quicksand_700Bold',
                  fontSize: [
                    { minWidth: Breakpoints.Laptop, value: 18 },
                    { minWidth: Breakpoints.Mobile, value: 18 },
                    { minWidth: Breakpoints.Desktop, value: 24 },
                  ],
                  marginBottom: 20,
                  marginLeft: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 45 },
                  ],
                  marginRight: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 45 },
                  ],
                  marginTop: 15,
                  textAlign: 'center',
                }
              ),
              dimensions.width
            )}
          >
            {'Leadership'}
          </Text>

          <HStack
            {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.HStackStyles(theme)['H Stack'].style,
                { flexWrap: 'wrap' }
              ),
              dimensions.width
            )}
          >
            {/* H Stack 2 */}
            <HStack
              {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.HStackStyles(theme)['H Stack'].style,
                  {
                    marginBottom: 25,
                    marginLeft: 20,
                    marginTop: 25,
                    width: 300,
                  }
                ),
                dimensions.width
              )}
            >
              <Image
                resizeMode={'cover'}
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                source={imageSource(Images['AndreasTrappe6'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { borderRadius: 50, marginRight: 15 }
                  ),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_700Bold',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Andreas von Buchwald\nCEO & Founder'}
              </Text>
            </HStack>
            {/* H Stack 3 */}
            <HStack
              {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.HStackStyles(theme)['H Stack'].style,
                  {
                    marginBottom: 25,
                    marginLeft: 20,
                    marginTop: 25,
                    width: 300,
                  }
                ),
                dimensions.width
              )}
            >
              <Image
                resizeMode={'cover'}
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                source={imageSource(Images['EmilCompressedSquare'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { borderRadius: 50, marginRight: 15 }
                  ),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Section heading'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Quicksand_700Bold',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Emil Ohlsen\nCOO'}
              </Text>
            </HStack>
          </HStack>
        </View>
      </LinearGradient>
      {/* Linear Gradient 8 */}
      <LinearGradient
        endX={100}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Primary gradient'].props}
        color1={theme.colors.branding.primary}
        color2={theme.colors.text.strong}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.LinearGradientStyles(theme)['Primary gradient'].style,
            { alignItems: 'center', paddingBottom: 45 }
          ),
          dimensions.width
        )}
      >
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: { minWidth: Breakpoints.Laptop, value: 'center' },
              alignItems: 'center',
              marginBottom: 30,
              marginTop: 45,
              maxWidth: [
                { minWidth: Breakpoints.Laptop, value: '75%' },
                { minWidth: Breakpoints.BigScreen, value: '50%' },
                { minWidth: Breakpoints.Desktop, value: '60%' },
              ],
            },
            dimensions.width
          )}
        >
          {/* Text 3 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Hero sub heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Hero sub heading'].style,
                {
                  color: theme.colors.background.brand,
                  fontFamily: 'Quicksand_700Bold',
                  fontSize: [
                    { minWidth: Breakpoints.Laptop, value: 18 },
                    { minWidth: Breakpoints.Mobile, value: 18 },
                    { minWidth: Breakpoints.Desktop, value: 24 },
                  ],
                  marginBottom: 20,
                  marginLeft: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 45 },
                  ],
                  marginRight: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 45 },
                  ],
                  marginTop: 15,
                  textAlign: 'center',
                }
              ),
              dimensions.width
            )}
          >
            {'Schedule online demo'}
          </Text>
          {/* Text 2 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['section body'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['section body'].style,
                {
                  fontFamily: [
                    {
                      minWidth: Breakpoints.Desktop,
                      value: 'Quicksand_400Regular',
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: 'Quicksand_400Regular',
                    },
                  ],
                  marginBottom: null,
                }
              ),
              dimensions.width
            )}
          >
            {
              "During a 30 minute video and screen-share call we'll take you through the key use-cases and confirm if there is a fit."
            }
          </Text>
        </View>

        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center' },
            dimensions.width
          )}
        >
          <Button
            iconPosition={'left'}
            onPress={() => {
              try {
                navigation.navigate('ScheduleDemoScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  backgroundColor: 'rgba(0, 0, 0, 0)',
                  borderColor: theme.colors.background.brand,
                  borderWidth: 1,
                  fontFamily: 'Quicksand_700Bold',
                  minWidth: 150,
                }
              ),
              dimensions.width
            )}
            title={'SCHEDULE DEMO'}
          />
        </View>
      </LinearGradient>

      <View
        style={StyleSheet.applyWidth(
          { alignItems: 'center', minHeight: 100 },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', marginTop: 20 },
            dimensions.width
          )}
        >
          <Image
            resizeMode={'cover'}
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            source={imageSource(Images['mainsightsfaviconlogo10243'])}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                { height: 50, marginBottom: 5, width: 50 }
              ),
              dimensions.width
            )}
          />
          {/* Text 5 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Section heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Section heading'].style,
                {
                  color: palettes.App.Orange,
                  fontFamily: 'Poppins_900Black',
                  fontSize: 20,
                  marginBottom: 25,
                  textAlign: 'center',
                }
              ),
              dimensions.width
            )}
          >
            {'M&A INSIGHTS'}
          </Text>

          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Section heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Section heading'].style,
                {
                  fontFamily: 'Quicksand_700Bold',
                  marginBottom: 10,
                  textAlign: 'center',
                }
              ),
              dimensions.width
            )}
          >
            {'Nordic Knowledge Partners ApS ("NKP")'}
          </Text>
          {/* Text 4 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Section heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Section heading'].style,
                {
                  fontFamily: [
                    {
                      minWidth: Breakpoints.Mobile,
                      value: 'Quicksand_700Bold',
                    },
                    {
                      minWidth: Breakpoints.Desktop,
                      value: 'Quicksand_400Regular',
                    },
                  ],
                  marginBottom: 10,
                  textAlign: 'center',
                }
              ),
              dimensions.width
            )}
          >
            {'All rights reserved, 2024.'}
          </Text>
          <IconButton
            onPress={() => {
              const handler = async () => {
                try {
                  await WebBrowser.openBrowserAsync(
                    'https://www.linkedin.com/company/nordic-knowledge-partners/'
                  );
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
            size={32}
            icon={'AntDesign/linkedin-square'}
            style={StyleSheet.applyWidth(
              { marginBottom: 20 },
              dimensions.width
            )}
          />
          {/* Text 2 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Section heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Section heading'].style,
                {
                  alignSelf: 'center',
                  fontFamily: 'Quicksand_500Medium',
                  marginBottom: 25,
                  textAlign: 'center',
                }
              ),
              dimensions.width
            )}
          >
            {'Strandvejen 60\n2900 Hellerup\nDenmark'}
          </Text>
          {/* Text 3 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Section heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Section heading'].style,
                {
                  alignSelf: 'center',
                  fontFamily: 'Quicksand_500Medium',
                  marginBottom: 25,
                  textAlign: 'center',
                }
              ),
              dimensions.width
            )}
          >
            {'info@mainsights.io'}
          </Text>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(HomeScreen);
