import React from 'react';
import {
  Button,
  Divider,
  HStack,
  Icon,
  IconButton,
  LinearGradient,
  ScreenContainer,
  TextInput,
  Touchable,
  VStack,
  withTheme,
} from '@draftbit/ui';
import * as WebBrowser from 'expo-web-browser';
import { Image, Modal, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoAPIApi from '../apis/XanoAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';

const ContactScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [textInput2Value, setTextInput2Value] = React.useState('');
  const [textInput3Value, setTextInput3Value] = React.useState('');
  const [textInput4Value, setTextInput4Value] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');
  const [tipErrMsg, setTipErrMsg] = React.useState('');
  const [tipPressed, setTipPressed] = React.useState(false);
  const xanoAPIAnonymousTipPOST = XanoAPIApi.useAnonymousTipPOST();

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={true}
      style={StyleSheet.applyWidth(
        {
          alignContent: 'center',
          alignItems: { minWidth: Breakpoints.Laptop, value: 'stretch' },
        },
        dimensions.width
      )}
    >
      {/* View 3 */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignContent: { minWidth: Breakpoints.Laptop, value: 'flex-start' },
            alignItems: { minWidth: Breakpoints.Laptop, value: 'stretch' },
            alignSelf: { minWidth: Breakpoints.Laptop, value: 'stretch' },
            backgroundColor: theme.colors.background.brand,
            justifyContent: {
              minWidth: Breakpoints.Laptop,
              value: 'flex-start',
            },
          },
          dimensions.width
        )}
      >
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignSelf: { minWidth: Breakpoints.Laptop, value: 'auto' },
              marginLeft: [
                { minWidth: Breakpoints.Desktop, value: 40 },
                { minWidth: Breakpoints.Laptop, value: 45 },
              ],
              marginRight: { minWidth: Breakpoints.Desktop, value: 45 },
            },
            dimensions.width
          )}
        >
          <HStack
            {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.HStackStyles(theme)['H Stack'].style,
                {
                  justifyContent: [
                    { minWidth: Breakpoints.Mobile, value: 'space-between' },
                    { minWidth: Breakpoints.Laptop, value: 'space-between' },
                  ],
                  marginBottom: [
                    { minWidth: Breakpoints.Laptop, value: 10 },
                    { minWidth: Breakpoints.Mobile, value: 10 },
                  ],
                  marginLeft: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Desktop, value: 40 },
                  ],
                  marginRight: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 45 },
                    { minWidth: Breakpoints.BigScreen, value: 250 },
                  ],
                  marginTop: 15,
                }
              ),
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('HomeScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <HStack
                {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.HStackStyles(theme)['H Stack'].style,
                    {
                      alignItems: [
                        { minWidth: Breakpoints.Laptop, value: 'flex-end' },
                        { minWidth: Breakpoints.Mobile, value: 'flex-end' },
                      ],
                    }
                  ),
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'cover'}
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  source={imageSource(Images['mainsightsfaviconlogo10243'])}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      { height: 30, marginRight: 5, width: 30 }
                    ),
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Section heading'].style,
                      {
                        color: palettes.App.Orange,
                        fontFamily: 'Poppins_900Black',
                        lineHeight: 14,
                        paddingBottom: 0,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'M&A INSIGHTS'}
                </Text>
              </HStack>
            </Touchable>
            <>
              {dimensions.width >= Breakpoints.Laptop ? null : (
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'hamburger_nav',
                        value: true,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  icon={'Ionicons/menu-sharp'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      { backgroundColor: null, color: theme.colors.text.strong }
                    ),
                    dimensions.width
                  )}
                  title={'\n'}
                />
              )}
            </>
            {/* H Stack 2 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <HStack
                  {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.HStackStyles(theme)['H Stack'].style,
                    dimensions.width
                  )}
                >
                  {/* Button 3 */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'login_options_laptop',
                          value: true,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors.branding.primary,
                          },
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_400Regular',
                          },
                          marginRight: {
                            minWidth: Breakpoints.Laptop,
                            value: 10,
                          },
                        }
                      ),
                      dimensions.width
                    )}
                    title={'LOG IN'}
                  />
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        navigation.navigate('AboutScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: {
                          minWidth: Breakpoints.Laptop,
                          value: 'rgba(0, 0, 0, 0)',
                        },
                        borderColor: {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors.branding.primary,
                        },
                        borderRadius: {
                          minWidth: Breakpoints.Laptop,
                          value: 5,
                        },
                        borderWidth: { minWidth: Breakpoints.Laptop, value: 1 },
                        color: {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors.branding.primary,
                        },
                        fontFamily: {
                          minWidth: Breakpoints.Laptop,
                          value: 'Quicksand_500Medium',
                        },
                        marginLeft: { minWidth: Breakpoints.Laptop, value: 10 },
                        marginRight: {
                          minWidth: Breakpoints.Laptop,
                          value: 10,
                        },
                      },
                      dimensions.width
                    )}
                    title={'ABOUT'}
                  />
                  {/* Button 2 */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        navigation.navigate('ContactScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors.background.brand,
                        },
                        borderColor: {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors.branding.primary,
                        },
                        borderRadius: {
                          minWidth: Breakpoints.Laptop,
                          value: 5,
                        },
                        borderWidth: { minWidth: Breakpoints.Laptop, value: 1 },
                        color: {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors.branding.primary,
                        },
                        fontFamily: {
                          minWidth: Breakpoints.Laptop,
                          value: 'Quicksand_700Bold',
                        },
                        marginLeft: { minWidth: Breakpoints.Laptop, value: 10 },
                        marginRight: {
                          minWidth: Breakpoints.Laptop,
                          value: 10,
                        },
                      },
                      dimensions.width
                    )}
                    title={'CONTACT'}
                  />
                  {/* Button 4 */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        navigation.navigate('ScheduleDemoScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors.background.brand,
                        },
                        borderColor: {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors.branding.primary,
                        },
                        borderRadius: {
                          minWidth: Breakpoints.Laptop,
                          value: 5,
                        },
                        borderWidth: { minWidth: Breakpoints.Laptop, value: 1 },
                        color: {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors.branding.primary,
                        },
                        fontFamily: {
                          minWidth: Breakpoints.Laptop,
                          value: 'Quicksand_400Regular',
                        },
                        marginLeft: { minWidth: Breakpoints.Laptop, value: 10 },
                        marginRight: {
                          minWidth: Breakpoints.Laptop,
                          value: 10,
                        },
                      },
                      dimensions.width
                    )}
                    title={'DEMO'}
                  />
                  {/* Button 4 2 */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        navigation.navigate('CompanyOwnersScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors.background.brand,
                        },
                        borderColor: {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors.branding.primary,
                        },
                        borderRadius: {
                          minWidth: Breakpoints.Laptop,
                          value: 5,
                        },
                        borderWidth: { minWidth: Breakpoints.Laptop, value: 1 },
                        color: {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors.branding.primary,
                        },
                        fontFamily: {
                          minWidth: Breakpoints.Laptop,
                          value: 'Quicksand_400Regular',
                        },
                        marginLeft: { minWidth: Breakpoints.Laptop, value: 10 },
                        marginRight: {
                          minWidth: Breakpoints.Laptop,
                          value: 10,
                        },
                      },
                      dimensions.width
                    )}
                    title={'FOR COMPANY OWNERS'}
                  />
                </HStack>
              )}
            </>
          </HStack>
        </View>
      </View>

      <LinearGradient
        endX={100}
        endY={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Primary gradient'].props}
        color1={palettes.App.Purple}
        color2={theme.colors.branding.primary}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.LinearGradientStyles(theme)['Primary gradient'].style,
            {
              alignItems: [
                { minWidth: Breakpoints.Laptop, value: 'flex-start' },
                { minWidth: Breakpoints.Mobile, value: 'stretch' },
                { minWidth: Breakpoints.Desktop, value: 'flex-start' },
              ],
              alignSelf: [
                { minWidth: Breakpoints.Desktop, value: 'auto' },
                { minWidth: Breakpoints.BigScreen, value: 'auto' },
              ],
              paddingBottom: [
                { minWidth: Breakpoints.Tablet, value: 45 },
                { minWidth: Breakpoints.Laptop, value: 50 },
              ],
            }
          ),
          dimensions.width
        )}
      >
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: [
                { minWidth: Breakpoints.Laptop, value: 'center' },
                { minWidth: Breakpoints.Desktop, value: 'flex-start' },
              ],
              alignItems: { minWidth: Breakpoints.Laptop, value: 'flex-start' },
              alignSelf: [
                { minWidth: Breakpoints.Desktop, value: 'flex-start' },
                { minWidth: Breakpoints.Laptop, value: 'auto' },
                { minWidth: Breakpoints.BigScreen, value: 'flex-start' },
              ],
              marginBottom: 45,
              marginLeft: [
                { minWidth: Breakpoints.BigScreen, value: 105 },
                { minWidth: Breakpoints.Desktop, value: 100 },
                { minWidth: Breakpoints.Laptop, value: 45 },
              ],
              maxWidth: [
                { minWidth: Breakpoints.BigScreen, value: '50%' },
                { minWidth: Breakpoints.Laptop, value: '75%' },
              ],
              width: { minWidth: Breakpoints.Desktop, value: '90%' },
            },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Hero heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Hero heading'].style,
                {
                  color: theme.colors.background.brand,
                  fontFamily: 'Quicksand_700Bold',
                  fontSize: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.BigScreen, value: 36 },
                    { minWidth: Breakpoints.Laptop, value: 24 },
                    { minWidth: Breakpoints.Desktop, value: 30 },
                  ],
                  marginBottom: 15,
                  marginLeft: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 20 },
                  ],
                  marginRight: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 20 },
                  ],
                  marginTop: 45,
                  textAlign: 'left',
                }
              ),
              dimensions.width
            )}
          >
            {'Get in touch'}
          </Text>
          {/* Text 2 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Hero sub heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Hero sub heading'].style,
                {
                  color: theme.colors.background.brand,
                  fontFamily: 'Quicksand_600SemiBold',
                  fontSize: [
                    { minWidth: Breakpoints.Mobile, value: 16 },
                    { minWidth: Breakpoints.Laptop, value: 18 },
                    { minWidth: Breakpoints.Desktop, value: 20 },
                  ],
                  marginBottom: 25,
                  marginLeft: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 20 },
                  ],
                  marginRight: [
                    { minWidth: Breakpoints.Laptop, value: 20 },
                    { minWidth: Breakpoints.Mobile, value: 20 },
                  ],
                  marginTop: 15,
                  textAlign: 'left',
                }
              ),
              dimensions.width
            )}
          >
            {
              'Please submit your information and an M&A Insights team member will follow up within 24 hours.'
            }
          </Text>
        </View>

        <Modal
          animationType={'none'}
          supportedOrientations={['portrait', 'landscape']}
          presentationStyle={'pageSheet'}
          transparent={true}
          visible={Constants['hamburger_nav']}
        >
          <View
            style={StyleSheet.applyWidth(
              { backgroundColor: theme.colors.background.brand },
              dimensions.width
            )}
          >
            <HStack
              {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.HStackStyles(theme)['H Stack'].style,
                  {
                    justifyContent: 'space-between',
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: 15,
                  }
                ),
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('HomeScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <HStack
                  {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.HStackStyles(theme)['H Stack'].style,
                      {
                        alignItems: [
                          { minWidth: Breakpoints.Mobile, value: 'flex-end' },
                          { minWidth: Breakpoints.Laptop, value: 'flex-end' },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <Image
                    resizeMode={'cover'}
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    source={imageSource(Images['mainsightsfaviconlogo10243'])}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'].style,
                        { height: 30, marginRight: 5, width: 30 }
                      ),
                      dimensions.width
                    )}
                  />
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Section heading'].style,
                        {
                          color: palettes.App.Orange,
                          fontFamily: 'Poppins_900Black',
                          lineHeight: 14,
                          paddingBottom: 0,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'M&A INSIGHTS'}
                  </Text>
                </HStack>
              </Touchable>
              <Button
                iconPosition={'left'}
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'hamburger_nav',
                      value: false,
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                icon={'Ionicons/menu-sharp'}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    {
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      color: theme.colors.text.strong,
                    }
                  ),
                  dimensions.width
                )}
                title={''}
              />
            </HStack>

            <VStack
              {...GlobalStyles.VStackStyles(theme)['V Stack'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.VStackStyles(theme)['V Stack'].style,
                  {
                    alignContent: {
                      minWidth: Breakpoints.Tablet,
                      value: 'center',
                    },
                    alignItems: {
                      minWidth: Breakpoints.Tablet,
                      value: 'center',
                    },
                    marginBottom: 25,
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: 25,
                  }
                ),
                dimensions.width
              )}
            >
              {/* Button 2 */}
              <Button
                iconPosition={'left'}
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'hamburger_nav',
                      value: false,
                    });
                    navigation.navigate('HomeScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    {
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      color: theme.colors.text.strong,
                      fontFamily: 'Quicksand_400Regular',
                      fontSize: { minWidth: Breakpoints.Tablet, value: 18 },
                      marginBottom: 10,
                      marginTop: 10,
                      textAlign: 'left',
                    }
                  ),
                  dimensions.width
                )}
                title={'Home'}
              />
              {/* Button 3 */}
              <Button
                iconPosition={'left'}
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'hamburger_nav',
                      value: false,
                    });
                    navigation.navigate('AboutScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    {
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      color: theme.colors.text.strong,
                      fontFamily: 'Quicksand_400Regular',
                      fontSize: { minWidth: Breakpoints.Tablet, value: 18 },
                      marginBottom: 10,
                      marginTop: 10,
                      textAlign: 'left',
                    }
                  ),
                  dimensions.width
                )}
                title={'About'}
              />
              {/* Button 3 2 */}
              <Button
                iconPosition={'left'}
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'hamburger_nav',
                      value: false,
                    });
                    navigation.navigate('CompanyOwnersScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    {
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      color: theme.colors.text.strong,
                      fontFamily: 'Quicksand_400Regular',
                      fontSize: { minWidth: Breakpoints.Tablet, value: 18 },
                      marginBottom: 10,
                      marginTop: 10,
                      textAlign: 'left',
                    }
                  ),
                  dimensions.width
                )}
                title={'For company owners'}
              />
              {/* Button 4 */}
              <Button
                iconPosition={'left'}
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'hamburger_nav',
                      value: false,
                    });
                    navigation.navigate('ContactScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    {
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      color: theme.colors.text.strong,
                      fontFamily: 'Quicksand_700Bold',
                      fontSize: { minWidth: Breakpoints.Tablet, value: 18 },
                      marginBottom: 10,
                      marginTop: 10,
                      textAlign: 'left',
                    }
                  ),
                  dimensions.width
                )}
                title={'Contact Us'}
              />
              {/* Button 5 */}
              <Button
                iconPosition={'left'}
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'hamburger_nav',
                      value: false,
                    });
                    navigation.navigate('ScheduleDemoScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    {
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      color: theme.colors.text.strong,
                      fontFamily: 'Quicksand_400Regular',
                      fontSize: { minWidth: Breakpoints.Tablet, value: 18 },
                      marginBottom: 10,
                      marginTop: 10,
                      textAlign: 'left',
                    }
                  ),
                  dimensions.width
                )}
                title={'Schedule Demo'}
              />
              <Button
                iconPosition={'left'}
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'hamburger_nav',
                      value: false,
                    });
                    setGlobalVariableValue({
                      key: 'login_options_mobile',
                      value: true,
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    {
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      borderColor: theme.colors.text.strong,
                      borderRadius: 5,
                      borderWidth: 1,
                      color: theme.colors.text.strong,
                      fontFamily: 'Quicksand_500Medium',
                      fontSize: { minWidth: Breakpoints.Tablet, value: 18 },
                      marginTop: 40,
                      maxWidth: 350,
                    }
                  ),
                  dimensions.width
                )}
                title={'Log In'}
              />
            </VStack>
          </View>
        </Modal>
        {/* Modal 2 2 */}
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <Modal
              animationType={'none'}
              supportedOrientations={['portrait', 'landscape']}
              presentationStyle={'pageSheet'}
              transparent={true}
              visible={Constants['login_options_laptop']}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors.background.brand,
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                  },
                  dimensions.width
                )}
              >
                <HStack
                  {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.HStackStyles(theme)['H Stack'].style,
                      {
                        justifyContent: 'space-between',
                        marginLeft: 20,
                        marginRight: 20,
                        marginTop: 15,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('HomeScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <HStack
                      {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.HStackStyles(theme)['H Stack'].style,
                          {
                            alignItems: [
                              {
                                minWidth: Breakpoints.Laptop,
                                value: 'flex-end',
                              },
                              {
                                minWidth: Breakpoints.Mobile,
                                value: 'flex-end',
                              },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      <Image
                        resizeMode={'cover'}
                        {...GlobalStyles.ImageStyles(theme)['Image'].props}
                        source={imageSource(
                          Images['mainsightsfaviconlogo10243']
                        )}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ImageStyles(theme)['Image'].style,
                            { height: 30, marginRight: 5, width: 30 }
                          ),
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Section heading']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Section heading']
                              .style,
                            {
                              color: palettes.App.Orange,
                              fontFamily: 'Poppins_900Black',
                              lineHeight: 14,
                              paddingBottom: 0,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'M&A INSIGHTS'}
                      </Text>
                    </HStack>
                  </Touchable>
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'login_options_laptop',
                          value: false,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    icon={'AntDesign/closecircleo'}
                    iconSize={25}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          color: theme.colors.text.strong,
                        }
                      ),
                      dimensions.width
                    )}
                    title={''}
                  />
                </HStack>

                <VStack
                  {...GlobalStyles.VStackStyles(theme)['V Stack'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.VStackStyles(theme)['V Stack'].style,
                      {
                        alignContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        alignItems: [
                          { minWidth: Breakpoints.Tablet, value: 'center' },
                          { minWidth: Breakpoints.Laptop, value: 'center' },
                        ],
                        alignSelf: {
                          minWidth: Breakpoints.Tablet,
                          value: 'center',
                        },
                        marginBottom: 25,
                        marginLeft: 20,
                        marginRight: 20,
                        marginTop: 25,
                        maxWidth: [
                          { minWidth: Breakpoints.Laptop, value: '75%' },
                          { minWidth: Breakpoints.BigScreen, value: '50%' },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Section heading'].style,
                        {
                          fontFamily: {
                            minWidth: Breakpoints.Laptop,
                            value: 'Quicksand_700Bold',
                          },
                          fontSize: { minWidth: Breakpoints.Laptop, value: 26 },
                          marginBottom: {
                            minWidth: Breakpoints.Laptop,
                            value: 20,
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Access M&A Insights anywhere and any time'}
                  </Text>

                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Section heading'].style,
                        {
                          fontFamily: 'Quicksand_400Regular',
                          fontSize: { minWidth: Breakpoints.Laptop, value: 18 },
                          marginBottom: {
                            minWidth: Breakpoints.Tablet,
                            value: 30,
                          },
                          textAlign: {
                            minWidth: Breakpoints.Tablet,
                            value: 'center',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {
                      'We will be launching our SaaS platform 2.0 in August 2024, which will also be available as a native mobile app in the App Store and Play Store, respectively.\n\nIn the meantime, please continue using the existing web application which is fully supported until we migrate to the new platform.'
                    }
                  </Text>

                  <VStack
                    {...GlobalStyles.VStackStyles(theme)['V Stack'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.VStackStyles(theme)['V Stack'].style,
                      dimensions.width
                    )}
                  >
                    {/* Button 4 */}
                    <Button
                      iconPosition={'left'}
                      onPress={() => {
                        const handler = async () => {
                          try {
                            navigation.navigate('HomeScreen');
                            setGlobalVariableValue({
                              key: 'hamburger_nav',
                              value: false,
                            });
                            await WebBrowser.openBrowserAsync(
                              'https://app.mainsights.io'
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'].style,
                          {
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            borderRadius: 5,
                            borderWidth: 1,
                            color: theme.colors.text.strong,
                            fontFamily: 'Quicksand_700Bold',
                            marginBottom: [
                              { minWidth: Breakpoints.Mobile, value: 10 },
                              { minWidth: Breakpoints.Laptop, value: 25 },
                            ],
                            marginTop: 20,
                            textAlign: 'left',
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Ok, continue to web version'}
                    />
                    {/* Text 3 */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Section heading']
                        .props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Section heading']
                            .style,
                          {
                            fontFamily: 'Quicksand_400Regular',
                            fontSize: {
                              minWidth: Breakpoints.Laptop,
                              value: 18,
                            },
                            marginBottom: [
                              { minWidth: Breakpoints.Laptop, value: 18.75 },
                              { minWidth: Breakpoints.Tablet, value: 30 },
                            ],
                            marginTop: {
                              minWidth: Breakpoints.Laptop,
                              value: 18.75,
                            },
                            textAlign: {
                              minWidth: Breakpoints.Tablet,
                              value: 'center',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Available from 12 Aug, 2024:'}
                    </Text>

                    <HStack
                      {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.HStackStyles(theme)['H Stack'].style,
                          {
                            marginBottom: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                            marginTop: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={palettes.App['Disabled Button']}
                        name={'AntDesign/qrcode'}
                        size={50}
                        style={StyleSheet.applyWidth(
                          {
                            marginRight: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                          },
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Section heading']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Section heading']
                              .style,
                            {
                              color: {
                                minWidth: Breakpoints.Laptop,
                                value: palettes.App['Disabled Button'],
                              },
                              fontFamily: {
                                minWidth: Breakpoints.Laptop,
                                value: 'Quicksand_400Regular',
                              },
                              fontSize: {
                                minWidth: Breakpoints.Laptop,
                                value: 18,
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'App Store (iOS)'}
                      </Text>
                    </HStack>
                    {/* H Stack 3 */}
                    <HStack
                      {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.HStackStyles(theme)['H Stack'].style,
                          {
                            marginBottom: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                            marginTop: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={palettes.App['Disabled Button']}
                        name={'AntDesign/qrcode'}
                        size={50}
                        style={StyleSheet.applyWidth(
                          {
                            marginRight: {
                              minWidth: Breakpoints.Laptop,
                              value: 20,
                            },
                          },
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Section heading']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Section heading']
                              .style,
                            {
                              color: {
                                minWidth: Breakpoints.Laptop,
                                value: palettes.App['Disabled Button'],
                              },
                              fontFamily: {
                                minWidth: Breakpoints.Laptop,
                                value: 'Quicksand_400Regular',
                              },
                              fontSize: {
                                minWidth: Breakpoints.Laptop,
                                value: 18,
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Play Store (Android)'}
                      </Text>
                    </HStack>
                  </VStack>
                </VStack>
              </View>
            </Modal>
          )}
        </>
        {/* Modal 2 */}
        <>
          {dimensions.width >= Breakpoints.Laptop ? null : (
            <Modal
              animationType={'none'}
              supportedOrientations={['portrait', 'landscape']}
              presentationStyle={'pageSheet'}
              transparent={true}
              visible={Constants['login_options_mobile']}
            >
              <View
                style={StyleSheet.applyWidth(
                  { backgroundColor: theme.colors.background.brand },
                  dimensions.width
                )}
              >
                <HStack
                  {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.HStackStyles(theme)['H Stack'].style,
                      {
                        justifyContent: 'space-between',
                        marginLeft: 20,
                        marginRight: 20,
                        marginTop: 15,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('HomeScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <HStack
                      {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.HStackStyles(theme)['H Stack'].style,
                          {
                            alignItems: [
                              {
                                minWidth: Breakpoints.Laptop,
                                value: 'flex-end',
                              },
                              {
                                minWidth: Breakpoints.Mobile,
                                value: 'flex-end',
                              },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      <Image
                        resizeMode={'cover'}
                        {...GlobalStyles.ImageStyles(theme)['Image'].props}
                        source={imageSource(
                          Images['mainsightsfaviconlogo10243']
                        )}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ImageStyles(theme)['Image'].style,
                            { height: 30, marginRight: 5, width: 30 }
                          ),
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Section heading']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Section heading']
                              .style,
                            {
                              color: palettes.App.Orange,
                              fontFamily: 'Poppins_900Black',
                              lineHeight: 14,
                              paddingBottom: 0,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'M&A INSIGHTS'}
                      </Text>
                    </HStack>
                  </Touchable>
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'login_options_mobile',
                          value: false,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    icon={'Ionicons/menu-sharp'}
                    iconSize={25}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          color: theme.colors.text.strong,
                        }
                      ),
                      dimensions.width
                    )}
                    title={''}
                  />
                </HStack>

                <VStack
                  {...GlobalStyles.VStackStyles(theme)['V Stack'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.VStackStyles(theme)['V Stack'].style,
                      {
                        alignContent: {
                          minWidth: Breakpoints.Tablet,
                          value: 'center',
                        },
                        alignItems: {
                          minWidth: Breakpoints.Tablet,
                          value: 'center',
                        },
                        alignSelf: {
                          minWidth: Breakpoints.Tablet,
                          value: 'center',
                        },
                        marginBottom: 25,
                        marginLeft: 20,
                        marginRight: 20,
                        marginTop: 25,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Section heading'].style,
                        {
                          fontFamily: 'Quicksand_400Regular',
                          marginBottom: {
                            minWidth: Breakpoints.Tablet,
                            value: 30,
                          },
                          textAlign: {
                            minWidth: Breakpoints.Tablet,
                            value: 'center',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {
                      'We will be launching our SaaS platform 2.0 in August 2024, which will also be available as a native mobile app in the App Store and Play Store, respectively.\n\nIn the meantime, please continue using the existing web application which is fully supported until we migrate to the new platform.'
                    }
                  </Text>
                  {/* Button 4 */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          navigation.navigate('HomeScreen');
                          setGlobalVariableValue({
                            key: 'hamburger_nav',
                            value: false,
                          });
                          await WebBrowser.openBrowserAsync(
                            'https://app.mainsights.io'
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    icon={'MaterialCommunityIcons/web'}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderRadius: 5,
                          borderWidth: 1,
                          color: theme.colors.text.strong,
                          fontFamily: 'Quicksand_700Bold',
                          marginBottom: 10,
                          marginTop: 20,
                          maxWidth: {
                            minWidth: Breakpoints.Tablet,
                            value: 300,
                          },
                          textAlign: 'left',
                          width: { minWidth: Breakpoints.Tablet, value: 300 },
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Continue with web version'}
                  />
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Section heading'].style,
                        {
                          fontFamily: 'Quicksand_400Regular',
                          marginBottom: [
                            { minWidth: Breakpoints.Tablet, value: 30 },
                            { minWidth: Breakpoints.Mobile, value: 18.75 },
                          ],
                          marginTop: 18.75,
                          textAlign: {
                            minWidth: Breakpoints.Tablet,
                            value: 'center',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Available from 12 Aug, 2024:'}
                  </Text>
                  {/* Button 2 */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        navigation.navigate('HomeScreen');
                        setGlobalVariableValue({
                          key: 'hamburger_nav',
                          value: false,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    disabled={true}
                    icon={'Ionicons/logo-apple-appstore'}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderColor: palettes.App['Disabled Button'],
                          borderRadius: 5,
                          borderWidth: 1,
                          color: palettes.App['Disabled Button'],
                          fontFamily: 'Quicksand_700Bold',
                          marginBottom: 10,
                          marginTop: 10,
                          maxWidth: {
                            minWidth: Breakpoints.Tablet,
                            value: 300,
                          },
                          textAlign: 'left',
                          width: { minWidth: Breakpoints.Tablet, value: 300 },
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Download in App Store (iOS)'}
                  />
                  {/* Button 3 */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        navigation.navigate('HomeScreen');
                        setGlobalVariableValue({
                          key: 'hamburger_nav',
                          value: false,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    disabled={true}
                    icon={'AntDesign/android1'}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderColor: palettes.App['Disabled Button'],
                          borderRadius: 5,
                          borderWidth: 1,
                          color: palettes.App['Disabled Button'],
                          fontFamily: 'Quicksand_700Bold',
                          marginBottom: 10,
                          marginTop: 20,
                          maxWidth: {
                            minWidth: Breakpoints.Tablet,
                            value: 300,
                          },
                          textAlign: 'left',
                          width: { minWidth: Breakpoints.Tablet, value: 300 },
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Download in Android Store'}
                  />
                </VStack>
              </View>
            </Modal>
          )}
        </>
      </LinearGradient>
      {/* Linear Gradient 2 */}
      <LinearGradient
        endX={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Primary gradient'].props}
        color1={theme.colors.text.strong}
        color2={palettes.App.Purple}
        endY={100}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.LinearGradientStyles(theme)['Primary gradient'].style,
            {
              alignItems: [
                { minWidth: Breakpoints.Mobile, value: 'flex-start' },
                { minWidth: Breakpoints.Laptop, value: 'flex-start' },
                { minWidth: Breakpoints.BigScreen, value: 'flex-start' },
                { minWidth: Breakpoints.Desktop, value: 'flex-start' },
              ],
              flexWrap: 'nowrap',
              paddingBottom: [
                { minWidth: Breakpoints.Mobile, value: 0 },
                { minWidth: Breakpoints.Tablet, value: 45 },
              ],
              paddingTop: 0,
            }
          ),
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: [
                { minWidth: Breakpoints.Laptop, value: 'center' },
                { minWidth: Breakpoints.BigScreen, value: 'flex-start' },
              ],
              alignItems: 'flex-start',
              borderColor: theme.colors.background.brand,
              marginBottom: 45,
              marginLeft: [
                { minWidth: Breakpoints.Mobile, value: 20 },
                { minWidth: Breakpoints.BigScreen, value: 105 },
                { minWidth: Breakpoints.Desktop, value: 100 },
                { minWidth: Breakpoints.Laptop, value: 45 },
              ],
              marginTop: 45,
              maxWidth: [
                { minWidth: Breakpoints.BigScreen, value: '50%' },
                { minWidth: Breakpoints.Laptop, value: '75%' },
              ],
              width: '90%',
            },
            dimensions.width
          )}
        >
          {/* Text 3 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Hero sub heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Hero sub heading'].style,
                {
                  color: theme.colors.background.brand,
                  fontFamily: 'Quicksand_700Bold',
                  fontSize: [
                    { minWidth: Breakpoints.Laptop, value: 18 },
                    { minWidth: Breakpoints.Mobile, value: 18 },
                    { minWidth: Breakpoints.Desktop, value: 24 },
                  ],
                  marginBottom: 10,
                  marginLeft: { minWidth: Breakpoints.Laptop, value: 20 },
                  marginRight: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 45 },
                  ],
                  marginTop: 15,
                  textAlign: 'left',
                }
              ),
              dimensions.width
            )}
          >
            {'Sales'}
          </Text>

          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['section body'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['section body'].style,
                {
                  fontFamily: [
                    {
                      minWidth: Breakpoints.Desktop,
                      value: 'Quicksand_400Regular',
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: 'Quicksand_400Regular',
                    },
                  ],
                  fontSize: 14,
                  marginBottom: 20,
                  marginLeft: null,
                  textAlign: 'left',
                }
              ),
              dimensions.width
            )}
          >
            {'sales@mainsights.co'}
          </Text>
        </View>
        <Divider
          {...GlobalStyles.DividerStyles(theme)['Divider'].props}
          color={theme.colors.border.brand}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.DividerStyles(theme)['Divider'].style,
              { marginLeft: 20, width: '90%' }
            ),
            dimensions.width
          )}
        />
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: { minWidth: Breakpoints.Laptop, value: 'center' },
              alignItems: 'flex-start',
              borderColor: theme.colors.background.brand,
              marginBottom: 45,
              marginLeft: [
                { minWidth: Breakpoints.Mobile, value: 20 },
                { minWidth: Breakpoints.BigScreen, value: 105 },
                { minWidth: Breakpoints.Desktop, value: 100 },
                { minWidth: Breakpoints.Laptop, value: 45 },
              ],
              marginTop: 30,
              maxWidth: [
                { minWidth: Breakpoints.Laptop, value: '75%' },
                { minWidth: Breakpoints.BigScreen, value: '50%' },
              ],
              width: '90%',
            },
            dimensions.width
          )}
        >
          {/* Text 3 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Hero sub heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Hero sub heading'].style,
                {
                  color: theme.colors.background.brand,
                  fontFamily: 'Quicksand_700Bold',
                  fontSize: [
                    { minWidth: Breakpoints.Laptop, value: 18 },
                    { minWidth: Breakpoints.Mobile, value: 18 },
                    { minWidth: Breakpoints.Desktop, value: 24 },
                  ],
                  marginBottom: 10,
                  marginLeft: { minWidth: Breakpoints.Laptop, value: 20 },
                  marginRight: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 45 },
                  ],
                  marginTop: 15,
                  textAlign: 'left',
                }
              ),
              dimensions.width
            )}
          >
            {'Technical Support'}
          </Text>

          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['section body'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['section body'].style,
                {
                  fontFamily: [
                    {
                      minWidth: Breakpoints.Desktop,
                      value: 'Quicksand_400Regular',
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: 'Quicksand_400Regular',
                    },
                  ],
                  fontSize: 14,
                  marginBottom: 20,
                  marginLeft: null,
                  textAlign: 'left',
                }
              ),
              dimensions.width
            )}
          >
            {'support@mainsights.co'}
          </Text>
        </View>
        {/* Divider 2 */}
        <Divider
          {...GlobalStyles.DividerStyles(theme)['Divider'].props}
          color={theme.colors.border.brand}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.DividerStyles(theme)['Divider'].style,
              { marginLeft: 20, width: '90%' }
            ),
            dimensions.width
          )}
        />
        {/* View 3 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: { minWidth: Breakpoints.Laptop, value: 'center' },
              alignItems: 'flex-start',
              borderColor: theme.colors.background.brand,
              marginBottom: 45,
              marginLeft: [
                { minWidth: Breakpoints.Mobile, value: 20 },
                { minWidth: Breakpoints.BigScreen, value: 105 },
                { minWidth: Breakpoints.Desktop, value: 100 },
                { minWidth: Breakpoints.Laptop, value: 45 },
              ],
              marginTop: 30,
              maxWidth: [
                { minWidth: Breakpoints.Laptop, value: '75%' },
                { minWidth: Breakpoints.BigScreen, value: '50%' },
              ],
              width: '90%',
            },
            dimensions.width
          )}
        >
          {/* Text 3 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Hero sub heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Hero sub heading'].style,
                {
                  color: theme.colors.background.brand,
                  fontFamily: 'Quicksand_700Bold',
                  fontSize: [
                    { minWidth: Breakpoints.Laptop, value: 18 },
                    { minWidth: Breakpoints.Mobile, value: 18 },
                    { minWidth: Breakpoints.Desktop, value: 24 },
                  ],
                  marginBottom: 10,
                  marginLeft: { minWidth: Breakpoints.Laptop, value: 20 },
                  marginRight: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 45 },
                  ],
                  marginTop: 15,
                  textAlign: 'left',
                }
              ),
              dimensions.width
            )}
          >
            {'Reporting deal(s) (for corp. finance and legal advisors)'}
          </Text>

          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['section body'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['section body'].style,
                {
                  fontFamily: [
                    {
                      minWidth: Breakpoints.Desktop,
                      value: 'Quicksand_400Regular',
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: 'Quicksand_400Regular',
                    },
                  ],
                  fontSize: 14,
                  marginBottom: 20,
                  marginLeft: null,
                  textAlign: 'left',
                }
              ),
              dimensions.width
            )}
          >
            {'advisors@mainsights.co'}
          </Text>
        </View>
        {/* Divider 3 */}
        <Divider
          {...GlobalStyles.DividerStyles(theme)['Divider'].props}
          color={theme.colors.border.brand}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.DividerStyles(theme)['Divider'].style,
              { marginLeft: 20, width: '90%' }
            ),
            dimensions.width
          )}
        />
        {/* View 4 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: { minWidth: Breakpoints.Laptop, value: 'center' },
              alignItems: 'flex-start',
              borderColor: theme.colors.background.brand,
              marginBottom: 45,
              marginLeft: [
                { minWidth: Breakpoints.Mobile, value: 20 },
                { minWidth: Breakpoints.BigScreen, value: 105 },
                { minWidth: Breakpoints.Desktop, value: 100 },
                { minWidth: Breakpoints.Laptop, value: 45 },
              ],
              marginTop: 30,
              maxWidth: [
                { minWidth: Breakpoints.Laptop, value: '75%' },
                { minWidth: Breakpoints.BigScreen, value: '50%' },
              ],
              width: '90%',
            },
            dimensions.width
          )}
        >
          {/* Text 3 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Hero sub heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Hero sub heading'].style,
                {
                  color: theme.colors.background.brand,
                  fontFamily: 'Quicksand_700Bold',
                  fontSize: [
                    { minWidth: Breakpoints.Laptop, value: 18 },
                    { minWidth: Breakpoints.Mobile, value: 18 },
                    { minWidth: Breakpoints.Desktop, value: 24 },
                  ],
                  marginBottom: 10,
                  marginLeft: { minWidth: Breakpoints.Laptop, value: 20 },
                  marginRight: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 45 },
                  ],
                  marginTop: 15,
                  textAlign: 'left',
                }
              ),
              dimensions.width
            )}
          >
            {'Content and case coverage'}
          </Text>

          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['section body'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['section body'].style,
                {
                  fontFamily: [
                    {
                      minWidth: Breakpoints.Mobile,
                      value: 'Quicksand_400Regular',
                    },
                    {
                      minWidth: Breakpoints.Desktop,
                      value: 'Quicksand_400Regular',
                    },
                  ],
                  fontSize: 14,
                  marginBottom: 20,
                  marginLeft: null,
                  textAlign: 'left',
                }
              ),
              dimensions.width
            )}
          >
            {'research@mainsights.co'}
          </Text>
        </View>
      </LinearGradient>
      {/* Linear Gradient 3 */}
      <LinearGradient
        endX={100}
        startX={0}
        startY={0}
        {...GlobalStyles.LinearGradientStyles(theme)['Primary gradient'].props}
        color1={theme.colors.text.strong}
        color2={theme.colors.branding.primary}
        endY={100}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.LinearGradientStyles(theme)['Primary gradient'].style,
            {
              alignItems: [
                { minWidth: Breakpoints.Mobile, value: 'flex-start' },
                { minWidth: Breakpoints.Laptop, value: 'flex-start' },
                { minWidth: Breakpoints.BigScreen, value: 'flex-start' },
                { minWidth: Breakpoints.Desktop, value: 'flex-start' },
              ],
              flexWrap: 'nowrap',
              paddingBottom: [
                { minWidth: Breakpoints.Tablet, value: 45 },
                { minWidth: Breakpoints.Mobile, value: 0 },
              ],
              paddingTop: 0,
            }
          ),
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: { minWidth: Breakpoints.Laptop, value: 'center' },
              alignItems: 'flex-start',
              marginBottom: [
                { minWidth: Breakpoints.Mobile, value: 45 },
                { minWidth: Breakpoints.Tablet, value: 25 },
              ],
              marginLeft: [
                { minWidth: Breakpoints.BigScreen, value: 105 },
                { minWidth: Breakpoints.Laptop, value: 45 },
                { minWidth: Breakpoints.Desktop, value: 100 },
              ],
              marginTop: 45,
              maxWidth: [
                { minWidth: Breakpoints.Laptop, value: '75%' },
                { minWidth: Breakpoints.BigScreen, value: '50%' },
              ],
            },
            dimensions.width
          )}
        >
          {/* Text 3 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Hero sub heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Hero sub heading'].style,
                {
                  color: theme.colors.background.brand,
                  fontFamily: 'Quicksand_700Bold',
                  fontSize: [
                    { minWidth: Breakpoints.Laptop, value: 18 },
                    { minWidth: Breakpoints.Mobile, value: 18 },
                    { minWidth: Breakpoints.Desktop, value: 24 },
                  ],
                  marginBottom: 20,
                  marginLeft: 20,
                  marginRight: [
                    { minWidth: Breakpoints.Mobile, value: 20 },
                    { minWidth: Breakpoints.Laptop, value: 45 },
                  ],
                  marginTop: 15,
                  textAlign: 'left',
                }
              ),
              dimensions.width
            )}
          >
            {'Tip us (anonymously) about a process or opportunity'}
          </Text>

          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['section body'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['section body'].style,
                {
                  fontFamily: [
                    {
                      minWidth: Breakpoints.Desktop,
                      value: 'Quicksand_400Regular',
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: 'Quicksand_700Bold',
                    },
                  ],
                  fontSize: 14,
                  marginBottom: 20,
                  textAlign: 'left',
                }
              ),
              dimensions.width
            )}
          >
            {
              "If you believe we should take a look at a particular situation or company, let us know. You can also contact a member of our team directly and we'll keep our dialogue strictly confidential."
            }
          </Text>
        </View>
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: [
                { minWidth: Breakpoints.BigScreen, value: 'flex-start' },
                { minWidth: Breakpoints.Desktop, value: 'center' },
              ],
              alignItems: [
                { minWidth: Breakpoints.Mobile, value: 'flex-start' },
                { minWidth: Breakpoints.BigScreen, value: 'center' },
                { minWidth: Breakpoints.Desktop, value: 'flex-start' },
              ],
              alignSelf: [
                { minWidth: Breakpoints.BigScreen, value: 'auto' },
                { minWidth: Breakpoints.Desktop, value: 'auto' },
              ],
              marginBottom: 45,
              marginLeft: [
                { minWidth: Breakpoints.BigScreen, value: 105 },
                { minWidth: Breakpoints.Laptop, value: 45 },
                { minWidth: Breakpoints.Desktop, value: 100 },
              ],
              maxWidth: [
                { minWidth: Breakpoints.BigScreen, value: '50%' },
                { minWidth: Breakpoints.Desktop, value: '75%' },
                { minWidth: Breakpoints.Laptop, value: '75%' },
              ],
            },
            dimensions.width
          )}
        >
          {/* V Stack 2 */}
          <VStack
            {...GlobalStyles.VStackStyles(theme)['V Stack'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.VStackStyles(theme)['V Stack'].style,
                {
                  alignSelf: {
                    minWidth: Breakpoints.BigScreen,
                    value: 'flex-start',
                  },
                  marginBottom: 20,
                  marginLeft: 20,
                  marginRight: 20,
                }
              ),
              dimensions.width
            )}
          >
            {/* Text 2 */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Section heading'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Section heading'].style,
                  {
                    color: theme.colors.background.brand,
                    fontFamily: 'Quicksand_400Regular',
                    marginBottom: 5,
                  }
                ),
                dimensions.width
              )}
            >
              {'Company or situation'}
            </Text>
            {/* Text Input 2 */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newTextInput2Value => {
                const textInputValue = newTextInput2Value;
                try {
                  setTextInput2Value(newTextInput2Value);
                } catch (err) {
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
              placeholder={'Company...'}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                  {
                    backgroundColor: theme.colors.text.light,
                    borderRadius: 5,
                    fontFamily: 'Quicksand_400Regular',
                    width: [
                      { minWidth: Breakpoints.Mobile, value: 300 },
                      { minWidth: Breakpoints.Tablet, value: 500 },
                    ],
                  }
                ),
                dimensions.width
              )}
              value={textInput2Value}
            />
          </VStack>
          {/* V Stack 3 */}
          <VStack
            {...GlobalStyles.VStackStyles(theme)['V Stack'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.VStackStyles(theme)['V Stack'].style,
                {
                  alignItems: {
                    minWidth: Breakpoints.BigScreen,
                    value: 'flex-start',
                  },
                  alignSelf: [
                    { minWidth: Breakpoints.BigScreen, value: 'flex-start' },
                    { minWidth: Breakpoints.Desktop, value: 'flex-start' },
                  ],
                  marginBottom: 20,
                  marginLeft: 20,
                  marginRight: 20,
                  minWidth: 200,
                }
              ),
              dimensions.width
            )}
          >
            {/* Text 3 */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Section heading'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Section heading'].style,
                  {
                    color: theme.colors.background.brand,
                    fontFamily: 'Quicksand_400Regular',
                    marginBottom: 5,
                  }
                ),
                dimensions.width
              )}
            >
              {'Description'}
            </Text>
            {/* Text Input 3 */}
            <TextInput
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newTextInput3Value => {
                const textInputValue = newTextInput3Value;
                try {
                  setTextInput3Value(newTextInput3Value);
                } catch (err) {
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
              autoCapitalize={'sentences'}
              multiline={true}
              placeholder={'Description...'}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                  {
                    backgroundColor: theme.colors.text.light,
                    borderRadius: 5,
                    fontFamily: 'Quicksand_400Regular',
                    height: 250,
                    minWidth: [
                      { minWidth: Breakpoints.BigScreen, value: 500 },
                      { minWidth: Breakpoints.Desktop, value: 500 },
                    ],
                    width: [
                      { minWidth: Breakpoints.Laptop, value: 500 },
                      { minWidth: Breakpoints.Mobile, value: 300 },
                      { minWidth: Breakpoints.Tablet, value: 500 },
                    ],
                  }
                ),
                dimensions.width
              )}
              textBreakStrategy={'simple'}
              value={textInput3Value}
            />
            {/* Text 4 */}
            <>
              {!(tipErrMsg !== '') ? null : (
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Section heading'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Section heading'].style,
                      {
                        color: theme.colors.background.danger,
                        fontFamily: 'Quicksand_400Regular',
                        marginBottom: 5,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {tipErrMsg}
                </Text>
              )}
            </>
            <Button
              iconPosition={'left'}
              onPress={() => {
                const handler = async () => {
                  try {
                    setTipPressed(true);
                    if (textInput2Value !== '' && textInput3Value !== '') {
                      (
                        await xanoAPIAnonymousTipPOST.mutateAsync({
                          company: textInput2Value,
                          message: textInput3Value,
                        })
                      )?.json;

                      showAlertUtil({
                        title: undefined,
                        message:
                          'We have received this information and will take it into consideration.',
                        buttonText: 'Ok',
                      });

                      setTextInput2Value('');
                      setTextInput3Value('');
                      setTipErrMsg('');
                    } else {
                      setTipErrMsg(
                        'Please ensure to fill all fields before submitting'
                      );
                    }

                    setTipPressed(false);
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              {...GlobalStyles.ButtonStyles(theme)['Button'].props}
              loading={tipPressed}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Button'].style,
                  {
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderColor: theme.colors.background.brand,
                    borderRadius: [
                      { minWidth: Breakpoints.BigScreen, value: 5 },
                      { minWidth: Breakpoints.Mobile, value: 5 },
                    ],
                    borderWidth: 1,
                    fontFamily: 'Quicksand_700Bold',
                    marginTop: [
                      { minWidth: Breakpoints.BigScreen, value: 20 },
                      { minWidth: Breakpoints.Desktop, value: 20 },
                      { minWidth: Breakpoints.Laptop, value: 20 },
                      { minWidth: Breakpoints.Tablet, value: 20 },
                      { minWidth: Breakpoints.Mobile, value: 20 },
                    ],
                    minWidth: { minWidth: Breakpoints.BigScreen, value: 250 },
                    position: {
                      minWidth: Breakpoints.BigScreen,
                      value: 'relative',
                    },
                    width: [
                      { minWidth: Breakpoints.Tablet, value: 500 },
                      { minWidth: Breakpoints.Mobile, value: 300 },
                    ],
                  }
                ),
                dimensions.width
              )}
              title={'SEND'}
            />
            {/* Text 2 */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['section body'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['section body'].style,
                  {
                    alignSelf: [
                      { minWidth: Breakpoints.BigScreen, value: 'flex-start' },
                      { minWidth: Breakpoints.Mobile, value: 'auto' },
                    ],
                    fontFamily: [
                      {
                        minWidth: Breakpoints.Desktop,
                        value: 'Quicksand_400Regular',
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: 'Quicksand_400Regular',
                      },
                    ],
                    fontSize: 14,
                    marginBottom: 20,
                    marginLeft: [
                      { minWidth: Breakpoints.Mobile, value: 0 },
                      { minWidth: Breakpoints.Laptop, value: 0 },
                      { minWidth: Breakpoints.Tablet, value: 0 },
                      { minWidth: Breakpoints.Desktop, value: 0 },
                    ],
                    marginTop: [
                      { minWidth: Breakpoints.Mobile, value: 20 },
                      { minWidth: Breakpoints.Tablet, value: 20 },
                    ],
                    textAlign: 'left',
                  }
                ),
                dimensions.width
              )}
            >
              {
                'Please note that we need concrete, credible information in order to research and verify any topic further and potentially cover it on our platform. We make no guarantee that the information submitted, in whole or in parts, will ever be covered in M&A Insights.'
              }
            </Text>
          </VStack>
        </View>
      </LinearGradient>

      <View
        style={StyleSheet.applyWidth(
          { alignItems: 'center', minHeight: 100 },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', marginTop: 20 },
            dimensions.width
          )}
        >
          <Image
            resizeMode={'cover'}
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            source={imageSource(Images['mainsightsfaviconlogo10243'])}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                { height: 50, marginBottom: 5, width: 50 }
              ),
              dimensions.width
            )}
          />
          {/* Text 5 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Section heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Section heading'].style,
                {
                  color: palettes.App.Orange,
                  fontFamily: 'Poppins_900Black',
                  fontSize: 20,
                  marginBottom: 25,
                  textAlign: 'center',
                }
              ),
              dimensions.width
            )}
          >
            {'M&A INSIGHTS'}
          </Text>

          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Section heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Section heading'].style,
                {
                  fontFamily: 'Quicksand_700Bold',
                  marginBottom: 10,
                  textAlign: 'center',
                }
              ),
              dimensions.width
            )}
          >
            {'Nordic Knowledge Partners ApS'}
          </Text>
          {/* Text 4 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Section heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Section heading'].style,
                {
                  fontFamily: [
                    {
                      minWidth: Breakpoints.Desktop,
                      value: 'Quicksand_400Regular',
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: 'Quicksand_700Bold',
                    },
                  ],
                  marginBottom: 10,
                  textAlign: 'center',
                }
              ),
              dimensions.width
            )}
          >
            {'All rights reserved, 2024.'}
          </Text>
          {/* Text 2 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Section heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Section heading'].style,
                {
                  alignSelf: 'center',
                  fontFamily: 'Quicksand_500Medium',
                  marginBottom: 25,
                  textAlign: 'center',
                }
              ),
              dimensions.width
            )}
          >
            {'Strandvejen 60\n2900 Hellerup\nDenmark'}
          </Text>
          {/* Text 3 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Section heading'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['Section heading'].style,
                {
                  alignSelf: 'center',
                  fontFamily: 'Quicksand_500Medium',
                  marginBottom: 25,
                  textAlign: 'center',
                }
              ),
              dimensions.width
            )}
          >
            {'info@mainsights.io'}
          </Text>
          <IconButton
            onPress={() => {
              const handler = async () => {
                try {
                  await WebBrowser.openBrowserAsync(
                    'https://www.linkedin.com/company/nordic-knowledge-partners/'
                  );
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
            size={32}
            icon={'AntDesign/linkedin-square'}
            style={StyleSheet.applyWidth(
              { marginBottom: 20 },
              dimensions.width
            )}
          />
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(ContactScreen);
